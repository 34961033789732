export const isPasswordFormatValid = (password: string): boolean => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/;
  return regex.test(password);
};

export const validatePasswordFormat = (password: string) => {
  if (!isPasswordFormatValid(password)) {
    throw Error(
      "Password should contain at least one lowercase and uppercase alphabet, one number, one special character @.#$!%*?&^, and with length between 8 and 15."
    );
  }
};

const isEmailFormatValid = (email: string) => {
  const emailRegex = new RegExp(/^[\w\-.]+@([\w\-]+\.)+[\w\-]{2,4}$/);
  return emailRegex.test(email);
};

export const validateEmailFormat = (email: string) => {
  if (!isEmailFormatValid(email)) {
    throw new Error("Email format is incorrect");
  }
};
