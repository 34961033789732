import React, { useMemo } from "react";
import useImage from "../../hook/useImage";
import Loading from "../../component/Loading";

interface ImageProps {
  image: string;
  size?: number;
  postId: number;
}

const PostImage: React.FC<ImageProps> = ({ postId, image: imageStr, size }) => {
  const { image, isLoading } = useImage("post", postId, imageStr);
  const imageUrl = useMemo(() => {
    return image ? URL.createObjectURL(image) : null;
  }, [image]);
  return (
    <div
      className="aspect-1/1 rounded-[6px] flex-1 bg-whitegray overflow-hidden flex justify-center items-center"
      style={{
        width: size ? size : undefined,
        maxWidth: size ? size : undefined,
      }}
    >
      {isLoading || !imageUrl ? (
        <Loading color="secondary" size={`${size ? size / 5 : 30}px`} />
      ) : (
        <img
          alt="Post Image"
          className="w-full h-full object-cover"
          src={imageUrl}
        />
      )}
    </div>
  );
};

export default PostImage;
