import { Collectible } from "../../types/Collectible";
import React, { useEffect, useState } from "react";
import { Currency } from "../../types/shared";
import { OrderReleaseType } from "../../types/Order";
import Modal from "../../component/Modal";
import Button from "../../component/Button";
import { FiEdit, FiPlus } from "react-icons/fi";
import Text from "../../component/Text";
import Input from "../../component/Input";
import Dropdown from "../../component/Dropdown";
import DatePicker from "../../component/DatePicker";
import useAmendCollectible from "../../hook/collectible/useAmendCollectible";
import useCreateCollectible from "../../hook/collectible/useCreateCollectible";
import FileUpload from "../../component/FileUpload";
import { compressFile, CompressLevel } from "../../utils/fileUtils";
import Switch from "../../component/Switch";

interface CollectibleErrors {
  name: string;
  brand: string;
  currency: string;
  price: string;
  addedAt: string;
  image: string;
  comment: string;
  general: string;
}

const initialErrors: CollectibleErrors = {
  name: "",
  brand: "",
  currency: "",
  price: "",
  addedAt: "",
  image: "",
  comment: "",
  general: "",
};

interface ModelProps {
  onOpen: () => void;
  onClose: () => void;
}

interface CreateCollectibleModelProps extends ModelProps {
  action: "CREATE";
}

interface EditCollectibleModelProps extends ModelProps {
  action: "EDIT";
  collectible: Collectible;
  image: Blob | null;
}

const CreateEditCollectibleModal: React.FC<
  CreateCollectibleModelProps | EditCollectibleModelProps
> = (props) => {
  const { action, onOpen, onClose } = props;
  const { amendCollectible, isLoading: isAmending } = useAmendCollectible();
  const { createCollectible, isLoading: isCreating } = useCreateCollectible();

  const isCreate = action === "CREATE";
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(
    action === "CREATE" ? "" : props.collectible.name
  );
  const [brand, setBrand] = useState(
    action === "CREATE" ? "" : props.collectible.brand
  );
  const [price, setPrice] = useState(isCreate ? 0 : props.collectible.price);
  const [currency, setCurrency] = useState(
    isCreate ? Currency.HKD : props.collectible.currency
  );
  const [comment, setComment] = useState(
    isCreate ? "" : props.collectible.comment
  );
  const [addedAt, setAddedAt] = useState(
    isCreate ? new Date() : new Date(props.collectible.addedAt)
  );

  const initImage = isCreate
    ? null
    : props.image
    ? new File([props.image], props.collectible.name)
    : null;
  const [image, setImage] = useState<File | null>(initImage);
  const [imageUpdated, setImageUpdated] = useState(false);
  const [isPublic, setPublic] = useState(
    isCreate ? true : props.collectible.public
  );

  const [errors, setErrors] = useState({ ...initialErrors });

  const setError = (key: keyof CollectibleErrors, value: string) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const reset = () => {
    setName(isCreate ? "" : props.collectible.name);
    setBrand(isCreate ? "" : props.collectible.brand);
    setPrice(isCreate ? 0 : props.collectible.price);
    setCurrency(isCreate ? Currency.HKD : props.collectible.currency);
    setComment(isCreate ? "" : props.collectible.comment);
    setAddedAt(new Date(isCreate ? new Date() : props.collectible.addedAt));
    setImageUpdated(false);
    setImage(initImage);
    setPublic(isCreate ? true : props.collectible.public);
    setErrors({ ...initialErrors });
  };

  useEffect(() => {
    reset();
  }, [props]);

  const validateCollectible = () => {
    let valid = true;
    setErrors(() => {
      return { ...initialErrors };
    });
    if (!name) {
      setError("name", "Required");
      valid = false;
    }
    if (!brand) {
      setError("brand", "Required");
      valid = false;
    }
    if (!addedAt) {
      setError("addedAt", "Required");
      valid = false;
    }
    return valid;
  };

  const onSubmit = () => {
    if (validateCollectible()) {
      if (!isCreate) {
        amendCollectible(
          [
            {
              id: props.collectible.id,
              name,
              brand,
              currency,
              price,
              image: props.collectible.image,
              public: isPublic,
              addedAt,
              comment,
            },
            // If this is null, it means image is not updated. Therefore, backend will not save the same image to blob
            imageUpdated ? image : null,
          ],
          {
            onSuccess: () => {
              setOpen(false);
              onClose();
            },
            onError: (err) => {
              setError(
                "general",
                err instanceof Error
                  ? err.message
                  : "Failed to edit the collectible."
              );
            },
          }
        );
      } else {
        createCollectible(
          [
            {
              name,
              brand,
              currency,
              price,
              addedAt,
              comment,
              public: isPublic,
            },
            image,
          ],
          {
            onSuccess: () => {
              setOpen(false);
            },
            onError: (err) => {
              setError(
                "general",
                err instanceof Error
                  ? err.message
                  : "Failed to create the collectible."
              );
            },
          }
        );
      }
    }
  };

  return (
    <Modal
      title={isCreate ? "Create Collectible" : "Edit Collectible"}
      open={open}
      onOpen={() => {
        setOpen(true);
        onOpen();
      }}
      onClose={() => {
        setOpen(false);
        reset();
        onClose();
      }}
      trigger={
        isCreate ? (
          <Button
            type="fill"
            text="Create"
            icon={<FiPlus size="20px" />}
            onClick={() => {}}
          />
        ) : (
          <Text type="white" pointer>
            <FiEdit className="mobile:text-[16px] text-[18px]" />
          </Text>
        )
      }
    >
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-col gap-[10px]">
          <div className="flex mobile:flex-row flex-col gap-[10px]">
            <div className="sm:w-[240px] mobile:w-[55%] h-fit aspect-3/4 bg-whitegray rounded-[6px] overflow-hidden">
              <FileUpload
                file={image}
                multiple={false}
                onChange={(image) => {
                  if (image) {
                    compressFile(
                      CompressLevel.MEDIUM,
                      image,
                      (result) => {
                        setImageUpdated(true);
                        setImage(result);
                      },
                      (err) => {
                        setError("image", err.message);
                      }
                    );
                  }
                }}
                type="image"
              />
            </div>
            <div className="grow flex flex-col">
              <Input
                type="text"
                title="Name"
                onChange={setName}
                value={name}
                error={errors.name}
              />
              <Input
                type="text"
                title="Brand"
                onChange={setBrand}
                value={brand}
                error={errors.brand}
              />
              <Dropdown
                title="Currency"
                value={currency}
                onChange={setCurrency}
                options={Object.values(Currency).map((value) => {
                  return {
                    text: value,
                    value: value,
                  };
                })}
              />
              <Input
                type="number"
                label={currency}
                title="Price"
                onChange={setPrice}
                value={price}
                error={errors.price}
              />
              <DatePicker
                title="Added to Collection on"
                type={OrderReleaseType.DATE}
                range={false}
                value={addedAt}
                onChange={setAddedAt}
              />
            </div>
          </div>
          <div>
            <Switch
              value={isPublic}
              onChange={setPublic}
              title="Public (Others will see it in your profile if this is enabled)"
            />
            <Input
              type="textarea"
              title="Comment"
              inputClassName="h-[120px]"
              onChange={setComment}
              value={comment}
            />
          </div>
        </div>
        {errors.general && (
          <div className="w-full flex justify-end">
            <Text type="danger" size="xs">
              {errors.general}
            </Text>
          </div>
        )}
        <div className="flex justify-end gap-[10px]">
          <Button
            type="outline"
            color="red"
            text="Cancel"
            onClick={() => {
              setOpen(false);
              reset();
              onClose();
            }}
          />
          <Button
            text="Save"
            loading={isCreating || isAmending}
            onClick={onSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateEditCollectibleModal;
