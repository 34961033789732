import React, { useEffect, useMemo } from "react";
import Text from "./Text";
import Dropdown from "./Dropdown";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { OrderReleaseType } from "../types/Order";
import { getDateUTC, getMonthByQuarter, getQuarter } from "../utils/dateUtils";

interface DatePickerProps {
  type?: OrderReleaseType;
  title?: string;
}

interface SingleDatePickerProps extends DatePickerProps {
  range: false;
  value: Date;
  onChange: (value: Date) => void;
  startFrom?: Date;
}

interface DateRangePickerProps extends DatePickerProps {
  range: true;
  from: Date;
  onFromChange: (value: Date) => void;
  to: Date;
  onToChange: (value: Date) => void;
}

type QuarterValue = 1 | 2 | 3 | 4;

const MONTH_OPTIONS: ReadonlyArray<{ text: string; value: number }> = [
  {
    text: "Jan",
    value: 0,
  },
  {
    text: "Feb",
    value: 1,
  },
  {
    text: "Mar",
    value: 2,
  },
  {
    text: "Apr",
    value: 3,
  },
  {
    text: "May",
    value: 4,
  },
  {
    text: "Jun",
    value: 5,
  },
  {
    text: "Jul",
    value: 6,
  },
  {
    text: "Aug",
    value: 7,
  },
  {
    text: "Sept",
    value: 8,
  },
  {
    text: "Oct",
    value: 9,
  },
  {
    text: "Nov",
    value: 10,
  },
  {
    text: "Dec",
    value: 11,
  },
];

const QUARTER_OPTIONS: ReadonlyArray<{ text: string; value: QuarterValue }> = [
  {
    text: "Q1",
    value: 1,
  },
  {
    text: "Q2",
    value: 2,
  },
  {
    text: "Q3",
    value: 3,
  },
  {
    text: "Q4",
    value: 4,
  },
];

const SingleDatePicker: React.FC<Omit<SingleDatePickerProps, "range">> = ({
  type,
  value,
  onChange,
  startFrom,
}) => {
  const quarter = useMemo<QuarterValue>(() => getQuarter(value), [value]);

  useEffect(() => {
    // Need to reset month when type changes from month to quarter as it loses details
    if (
      type === OrderReleaseType.QUARTER &&
      getMonthByQuarter(quarter) !== value.getMonth()
    ) {
      onMonthChange(getMonthByQuarter(quarter));
    }
  }, [type, value, quarter]);

  useEffect(() => {
    // When from date changes to a date larger than to date, need to reset the to date
    if (startFrom && startFrom > value) {
      onChange(new Date(startFrom));
    }
  }, [startFrom, value]);

  const currentYear = new Date().getFullYear();

  const getYearOptions = () => {
    const years = [];
    for (let i = currentYear - 50; i < currentYear + 100; i += 1) {
      if (startFrom && i < startFrom.getFullYear()) {
        continue;
      }
      years.push(i);
    }
    return years.map((year) => ({ value: year, text: year.toString() }));
  };

  const monthOptions = useMemo(() => {
    return MONTH_OPTIONS.filter(
      (option) =>
        !startFrom ||
        value.getFullYear() > startFrom.getFullYear() ||
        option.value >= startFrom.getMonth()
    );
  }, [startFrom, value]);

  const quarterOptions = useMemo(() => {
    return QUARTER_OPTIONS.filter(
      (option) =>
        !startFrom ||
        value.getFullYear() > startFrom.getFullYear() ||
        getMonthByQuarter(option.value) >= startFrom.getMonth()
    );
  }, [startFrom, value]);

  const onYearChange = (year: number) => {
    const newDate =
      type === OrderReleaseType.YEAR
        ? getDateUTC(year, 0, 1, 0, 0, 0, 0)
        : type === OrderReleaseType.QUARTER
        ? getDateUTC(year, getMonthByQuarter(quarter), 1, 0, 0, 0, 0)
        : type === OrderReleaseType.MONTH
        ? getDateUTC(year, value.getMonth(), 1, 0, 0, 0, 0)
        : getDateUTC(year, value.getMonth(), value.getDate(), 0, 0, 0, 0);
    onChange(newDate);
  };

  const onMonthChange = (month: number) => {
    const newDate = getDateUTC(value.getFullYear(), month, 1, 0, 0, 0, 0);
    onChange(newDate);
  };

  const onQuarterChange = (quarter: 1 | 2 | 3 | 4) => {
    const newDate = getDateUTC(
      value.getFullYear(),
      getMonthByQuarter(quarter),
      1,
      0,
      0,
      0,
      0
    );
    onChange(newDate);
  };

  if (type === OrderReleaseType.QUARTER) {
    return (
      <div className="w-fit flex items-center bg-whitegray rounded-[6px]">
        <Dropdown
          value={value.getFullYear()}
          onChange={onYearChange}
          options={getYearOptions()}
        />
        <div className="w-[1px] h-[20px] bg-secondary" />
        <Dropdown
          value={quarter}
          onChange={onQuarterChange}
          options={quarterOptions}
        />
      </div>
    );
  }

  if (type === OrderReleaseType.YEAR) {
    return (
      <div className="w-fit flex items-center bg-whitegray rounded-[6px]">
        <Dropdown
          value={value.getFullYear()}
          onChange={onYearChange}
          options={getYearOptions()}
        />
      </div>
    );
  }

  if (type === OrderReleaseType.MONTH) {
    return (
      <div className="w-fit flex items-center bg-whitegray rounded-[6px]">
        <Dropdown
          value={value.getFullYear()}
          onChange={onYearChange}
          options={getYearOptions()}
        />
        <div className="w-[1px] h-[20px] bg-secondary" />
        <Dropdown
          value={value.getMonth()}
          onChange={onMonthChange}
          options={monthOptions}
        />
      </div>
    );
  }
  return (
    <MuiDatePicker
      sx={{
        boxShadow: "none",
        borderRadius: "6px",
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        ".MuiInputBase-input": {
          width: "100px",
          padding: "9.5px",
          fontSize: "14px",
          color: "#444444",
        },
        ".MuiSvgIcon-root": {
          fontSize: "16px",
        },
        cursor: "pointer",
      }}
      value={DateTime.fromJSDate(value)}
      shouldDisableDate={(date) => {
        return !!startFrom && startFrom > date.toJSDate();
      }}
      shouldDisableYear={(year) => {
        return (
          !!startFrom && startFrom.getFullYear() > year.toJSDate().getFullYear()
        );
      }}
      onChange={(newValue) => {
        if (newValue) {
          onChange(
            getDateUTC(
              newValue.year,
              newValue.month - 1,
              newValue.day,
              0,
              0,
              0,
              0
            )
          );
        }
      }}
    />
  );
};

const DatePicker: React.FC<SingleDatePickerProps | DateRangePickerProps> = (
  props
) => {
  return (
    <div>
      {props.title && (
        <div>
          <Text type="secondary" size="xs">
            {props.title}
          </Text>
        </div>
      )}
      {
        <div className="w-fit flex items-center gap-[10px] bg-whitegray rounded-[6px]">
          {props.range ? (
            <>
              <SingleDatePicker
                type={props.type}
                value={props.from}
                onChange={props.onFromChange}
              />
              <Text type="secondary">-</Text>
              <SingleDatePicker
                type={props.type}
                value={props.to}
                onChange={props.onToChange}
                startFrom={props.from}
              />
            </>
          ) : (
            <SingleDatePicker
              type={props.type}
              value={props.value}
              onChange={props.onChange}
            />
          )}
        </div>
      }
    </div>
  );
};

export default DatePicker;
