import { useMutation } from "@tanstack/react-query";
import { PostDto } from "../../types/Post";
import httpClient from "../../http/httpClient";
import { updatePostBy } from "./utils";

const useAddFavoritePost = () => {
  const { mutate, isPending } = useMutation<unknown, unknown, PostDto>({
    mutationFn: (post) =>
      post.addedFavorite
        ? httpClient.delete(`/post/favorite/${post.id}`)
        : httpClient.post(`/post/favorite/${post.id}`),
    onSuccess: (_, post) => {
      updatePostBy(post.id, (post) => {
        if (post.addedFavorite) {
          post.favoriteCount -= 1;
          post.addedFavorite = false;
        } else {
          post.favoriteCount += 1;
          post.addedFavorite = true;
        }
      });
    },
  });

  return {
    addFavorite: mutate,
    adding: isPending,
  };
};

export default useAddFavoritePost;
