import { Currency } from "./shared";

export enum OrderStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}

export enum OrderReleaseType {
  YEAR = "YEAR",
  QUARTER = "QUARTER",
  MONTH = "MONTH",
  DATE = "DATE",
}

export interface Order {
  id: number;
  name: string;
  brand: string;
  currency: Currency;
  deposit: number;
  balance: number;
  image: string;
  status: OrderStatus;
  releaseType: OrderReleaseType;
  releaseFromDate: string;
  releaseToDate: string | null;
  comment: string;
}
