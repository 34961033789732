import { Order, OrderReleaseType } from "../../types/Order";
import React, { useEffect, useState } from "react";
import useAmendOrder from "../../hook/order/useAmendOrder";
import Text from "../../component/Text";
import { FiEdit, FiPlus } from "react-icons/fi";
import Input from "../../component/Input";
import Dropdown from "../../component/Dropdown";
import { Currency } from "../../types/shared";
import Switch from "../../component/Switch";
import DatePicker from "../../component/DatePicker";
import Button from "../../component/Button";
import Modal from "../../component/Modal";
import useCreateOrder from "../../hook/order/useCreateOrder";
import FileUpload from "../../component/FileUpload";
import { compressFile, CompressLevel } from "../../utils/fileUtils";

interface OrderErrors {
  name: string;
  brand: string;
  deposit: string;
  balance: string;
  currency: string;
  releaseType: string;
  fromDate: string;
  toDate: string;
  image: string;
  general: string;
}

const initialErrors: OrderErrors = {
  name: "",
  brand: "",
  deposit: "",
  balance: "",
  currency: "",
  releaseType: "",
  fromDate: "",
  toDate: "",
  image: "",
  general: "",
};

interface CreateOrderModalProps {
  action: "CREATE";
}

interface EditOrderModalProps {
  action: "EDIT";
  order: Order;
  image: Blob | null;
}

const CreateEditOrderModal: React.FC<
  CreateOrderModalProps | EditOrderModalProps
> = (props) => {
  const { action } = props;
  const isCreate = action === "CREATE";
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(action === "CREATE" ? "" : props.order.name);
  const [brand, setBrand] = useState(
    action === "CREATE" ? "" : props.order.brand
  );
  const [deposit, setDeposit] = useState(isCreate ? 0 : props.order.deposit);
  const [balance, setBalance] = useState(isCreate ? 0 : props.order.balance);
  const [currency, setCurrency] = useState(
    isCreate ? Currency.HKD : props.order.currency
  );
  const [comment, setComment] = useState(isCreate ? "" : props.order.comment);
  const [releaseType, setReleaseType] = useState(
    isCreate ? OrderReleaseType.QUARTER : props.order.releaseType
  );
  const [fromDate, setFromDate] = useState(
    isCreate ? new Date() : new Date(props.order.releaseFromDate)
  );
  const [toDate, setToDate] = useState(
    isCreate
      ? new Date()
      : props.order.releaseToDate
      ? new Date(props.order.releaseToDate)
      : new Date(props.order.releaseFromDate)
  );

  const initImage = isCreate
    ? null
    : props.image
    ? new File([props.image], props.order.name)
    : null;

  const [image, setImage] = useState<File | null>(initImage);
  const [imageUpdated, setImageUpdated] = useState(false);

  const [isRange, setIsRange] = useState(
    isCreate ? true : !!props.order.releaseToDate
  );
  const [errors, setErrors] = useState<OrderErrors>({ ...initialErrors });
  const { amendOrder, isLoading: isAmending } = useAmendOrder();
  const { createOrder, isLoading: isCreating } = useCreateOrder();

  const setError = (key: keyof OrderErrors, value: string) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    reset();
  }, [props]);

  const reset = () => {
    setName(isCreate ? "" : props.order.name);
    setBrand(isCreate ? "" : props.order.brand);
    setDeposit(isCreate ? 0 : props.order.deposit);
    setBalance(isCreate ? 0 : props.order.balance);
    setCurrency(isCreate ? Currency.HKD : props.order.currency);
    setComment(isCreate ? "" : props.order.comment);
    setReleaseType(
      isCreate ? OrderReleaseType.QUARTER : props.order.releaseType
    );
    setFromDate(new Date(isCreate ? new Date() : props.order.releaseFromDate));
    setToDate(
      isCreate
        ? new Date()
        : props.order.releaseToDate
        ? new Date(props.order.releaseToDate)
        : new Date(props.order.releaseFromDate)
    );
    setImage(initImage);
    setImageUpdated(false);
    setIsRange(isCreate ? true : !!props.order.releaseToDate);
    setErrors({ ...initialErrors });
  };

  const validateOrder = () => {
    let valid = true;
    setErrors(() => {
      return { ...initialErrors };
    });
    if (!name) {
      setError("name", "Required");
      valid = false;
    }
    if (!brand) {
      setError("brand", "Required");
      valid = false;
    }
    if (isRange && !toDate) {
      setError("toDate", "Required");
      valid = false;
    }
    return valid;
  };

  const onSubmit = () => {
    if (validateOrder()) {
      if (isCreate) {
        createOrder(
          [
            {
              name,
              brand,
              currency,
              deposit,
              balance,
              releaseType,
              releaseFromDate: fromDate,
              releaseToDate: isRange ? toDate : null,
              comment,
            },
            image,
          ],
          {
            onSuccess: () => {
              setOpen(false);
            },
            onError: (err) => {
              if (err instanceof Error) {
                setError("general", err.message);
              } else {
                setError("general", "Failed to create the order.");
              }
            },
          }
        );
      } else {
        amendOrder(
          [
            {
              id: props.order.id,
              name,
              brand,
              currency,
              deposit,
              balance,
              releaseType,
              image: props.order.image,
              releaseFromDate: fromDate,
              releaseToDate: isRange ? toDate : null,
              comment,
            },
            // If this is null, it means image is not updated. Therefore, backend will not save the same image to blob
            imageUpdated ? image : null,
          ],
          {
            onSuccess: () => {
              setOpen(false);
            },
            onError: (err) => {
              if (err instanceof Error) {
                setError("general", err.message);
              } else {
                setError("general", "Failed to edit the order.");
              }
            },
          }
        );
      }
    }
  };

  return (
    <Modal
      title={isCreate ? "Create Order" : "Edit Order"}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false);
        reset();
      }}
      trigger={
        isCreate ? (
          <Button
            type="fill"
            text="Create"
            icon={<FiPlus size="20px" />}
            onClick={() => {}}
          />
        ) : (
          <Text type="secondary" pointer>
            <FiEdit className="mobile:text-[16px] text-[18px]" />
          </Text>
        )
      }
    >
      <div className="flex flex-col gap-[10px]">
        <div>
          <div className="flex mobile:flex-row flex-col gap-[10px]">
            <div className="sm:w-[240px] mobile:w-[55%] w-full h-fit aspect-3/4 bg-whitegray rounded-[6px] overflow-hidden">
              <FileUpload
                type="image"
                file={image}
                multiple={false}
                onChange={(image) => {
                  if (image) {
                    compressFile(
                      CompressLevel.MEDIUM,
                      image,
                      (result) => {
                        setImageUpdated(true);
                        setImage(result);
                      },
                      (err) => {
                        setError("image", err.message);
                      }
                    );
                  }
                }}
              />
            </div>
            <div className="grow flex flex-col">
              <Input
                type="text"
                title="Name"
                onChange={setName}
                value={name}
                error={errors.name}
              />
              <Input
                type="text"
                title="Brand"
                onChange={setBrand}
                value={brand}
                error={errors.brand}
              />
              <Dropdown
                title="Currency"
                value={currency}
                onChange={setCurrency}
                options={Object.values(Currency).map((value) => {
                  return {
                    text: value,
                    value: value,
                  };
                })}
              />
              <Input
                type="number"
                label={currency}
                title="Deposit"
                onChange={setDeposit}
                value={deposit}
                error={errors.deposit}
              />
              <Input
                type="number"
                label={currency}
                title="Balance"
                onChange={setBalance}
                value={balance}
                error={errors.balance}
              />
            </div>
          </div>
          <div>
            <div className="flex sm:flex-row flex-col gap-[10px]">
              <Dropdown
                title="Type"
                value={releaseType}
                onChange={setReleaseType}
                options={Object.values(OrderReleaseType).map((releaseType) => ({
                  text: releaseType,
                  value: releaseType,
                }))}
              />
              <Switch title="Range" value={isRange} onChange={setIsRange} />
              {isRange ? (
                <DatePicker
                  title="Release"
                  type={releaseType}
                  range={true}
                  from={fromDate}
                  to={toDate}
                  onFromChange={setFromDate}
                  onToChange={setToDate}
                />
              ) : (
                <DatePicker
                  title="Release"
                  type={releaseType}
                  range={false}
                  value={fromDate}
                  onChange={setFromDate}
                />
              )}
            </div>
            <Input
              type="textarea"
              title="Comment"
              inputClassName="h-[120px]"
              onChange={setComment}
              value={comment}
            />
          </div>
        </div>
        {errors.general && (
          <div className="w-full flex justify-end">
            <Text type="danger" size="xs">
              {errors.general}
            </Text>
          </div>
        )}
        <div className="flex justify-end gap-[10px]">
          <Button
            type="outline"
            color="red"
            text="Cancel"
            onClick={() => {
              setOpen(false);
              reset();
            }}
          />
          <Button
            loading={isCreate ? isCreating : isAmending}
            text="Save"
            onClick={onSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateEditOrderModal;
