import { AiOutlineClose } from "react-icons/ai";
import Text from "../component/Text";
import React, { ReactNode, useEffect, useMemo } from "react";
import { useRecoilState } from "recoil";
import { showMobileMenuState } from "../state/headerState";
import useSelf from "../hook/user/useSelf";
import { NavLink, useNavigate } from "react-router-dom";
import useAvatar from "../hook/profile/useAvatar";
import { CiDeliveryTruck } from "react-icons/ci";
import { BiCollection } from "react-icons/bi";
import { IoChatbubblesOutline, IoPersonOutline } from "react-icons/io5";
import { SelfDto } from "../types/User";
import { PiNewspaper } from "react-icons/pi";
import { RxEnter, RxExit } from "react-icons/rx";
import useSignOut from "../hook/auth/useSignOut";
import { FiUserPlus } from "react-icons/fi";

const Avatar = ({ src }: { src: string | null }) => {
  return src ? (
    <img alt="Avatar Image" className="w-full h-full object-cover" src={src} />
  ) : (
    <div className="w-full h-full flex justify-center items-center text-secondary opacity-60">
      <IoPersonOutline size="28px" />
    </div>
  );
};

const Tab = ({
  icon,
  text,
  link,
  border = false,
  onClick,
}: {
  icon: ReactNode;
  text: string;
  link?: string;
  border?: boolean;
  onClick?: () => void;
}) => {
  const [_, setShowMenu] = useRecoilState(showMobileMenuState);
  const baseClassname = `px-[10px] py-[15px] flex items-center gap-[10px] hover:bg-whitegray rounded-[6px] ${
    border ? "border-[1px] border-secondary" : ""
  }`;
  return link ? (
    <NavLink
      className={({ isActive }) => {
        return `${baseClassname} ${isActive ? "bg-whitegray" : "bg-white"}`;
      }}
      to={link}
      onClick={() => setShowMenu(false)}
    >
      <div className="w-[24px] text-primary">{icon}</div>
      <Text size="base">{text}</Text>
    </NavLink>
  ) : (
    <div className={`${baseClassname}`} onClick={onClick}>
      <div className="w-[24px] text-primary">{icon}</div>
      <Text size="base">{text}</Text>
    </div>
  );
};

const AuthenticatedTab = ({
  user,
  icon,
  link,
  text,
}: {
  user: SelfDto | null;
  icon: ReactNode;
  link: string;
  text: string;
}) => {
  if (!user) return null;
  return <Tab icon={icon} text={text} link={link} />;
};

const MobileMenuContent = () => {
  const navigate = useNavigate();
  const { self } = useSelf();
  const { signout } = useSignOut();
  const { avatar } = useAvatar(self?.avatar ?? null);
  const [showMenu, setShowMenu] = useRecoilState(showMobileMenuState);

  const avatarUrl = useMemo(() => {
    return avatar ? URL.createObjectURL(avatar) : null;
  }, [avatar]);

  // Disable scroll when the menu is open
  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showMenu]);

  return (
    <>
      <div
        className={`fixed w-[100vw] h-screen supports-h-dvh:h-dvh  bg-black transition-opacity duration-300 ${
          showMenu ? "opacity-50 z-10" : "opacity-0 z-[-1]"
        }`}
        onClick={() => setShowMenu(false)}
      />
      <div
        className={`fixed z-10 w-[100vw] h-[90vh] supports-h-dvh:h-[90dvh] left-0 ${
          showMenu
            ? "bottom-0"
            : "bottom-[-100vh] supports-h-dvh:bottom-[-100dvh]"
        } bg-white transition-top duration-300 flex flex-col rounded-t-[15px] py-[20px] px-[30px] gap-[20px]`}
      >
        <div className="header relative flex justify-between">
          <div className="profile flex items-center gap-[10px]">
            <div className="sm:hidden block w-[60px] h-[60px] rounded-[30px] bg-whitegray overflow-hidden">
              <Avatar src={avatarUrl} />
            </div>
            {!self && (
              <div>
                <Text type="secondary" inline={false} size="lg">
                  Please Sign In
                </Text>
              </div>
            )}
            {self && (
              <div>
                <Text inline={false} size="xl" weight="bold">
                  {self.nickname || "A Collector"}
                </Text>
                <Text inline={false} type="secondary" size="sm">
                  {self.username}
                </Text>
              </div>
            )}
          </div>
          <div>
            <Text
              type="primary"
              pointer
              onClick={() => {
                setShowMenu(false);
              }}
            >
              <AiOutlineClose size="26px" />
            </Text>
          </div>
        </div>
        <div className="w-full h-[1px] bg-gray-200" />
        <div className="items w-full grow">
          <Tab icon={<PiNewspaper size="20px" />} text="News" link="/news" />
          <Tab
            icon={<IoChatbubblesOutline size="18px" />}
            text="Community"
            link="/community"
          />
          <AuthenticatedTab
            icon={<CiDeliveryTruck size="24px" />}
            text="Orders"
            link="/orders"
            user={self}
          />
          <AuthenticatedTab
            icon={<BiCollection size="18px" />}
            text="Collection"
            link="/collection"
            user={self}
          />
          <AuthenticatedTab
            icon={<IoPersonOutline size="18px" />}
            text="Profile"
            link="/profile"
            user={self}
          />
        </div>
        {!self && (
          <div className="flex flex-col gap-[10px]">
            <Tab
              icon={<RxEnter size="18px" />}
              text="Sign In"
              link="/signin"
              border
            />
            <Tab
              icon={<FiUserPlus size="18px" />}
              text="Sign Up"
              link="/signup"
              border
            />
          </div>
        )}
        {self && (
          <div>
            <Tab
              border
              icon={<RxExit size="18px" />}
              text="Sign Out"
              onClick={() => {
                signout();
                navigate("/");
                setShowMenu(false);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MobileMenuContent;
