import { useQuery } from "@tanstack/react-query";
import { Collectible } from "../../types/Collectible";
import { QueryKey } from "../../query/queryKey";
import httpClient from "../../http/httpClient";

const useCollectibles = (userId?: number) => {
  const { data, error, isLoading } = useQuery<Array<Collectible> | null>({
    queryKey: userId
      ? [QueryKey.USER_COLLECTIBLES, userId]
      : [QueryKey.COLLECTIBLES],
    queryFn: () =>
      httpClient.get(
        userId ? `/collectible/list/${userId}` : "/collectible/list"
      ),
    gcTime: 3600000,
    staleTime: 1800000,
    retry: 0,
  });

  return {
    collectibles: data ?? [],
    error,
    isLoading,
  };
};

export default useCollectibles;
