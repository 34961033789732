import { ReferenceType } from "./shared";

export enum NotificationType {
  REPLY = "REPLY",
  FOLLOW = "FOLLOW",
  LIKE = "LIKE",
}

interface NotificationDto {
  id: number;
  type: NotificationType;
  read: boolean;
  createdAt: string;
}

interface NotificationDtoWithFromUser extends NotificationDto {
  fromUser: {
    id: number;
    username: string;
    nickname: string | null;
    avatar: string | null;
    followed: boolean;
  };
}

interface LikeNotificationDto extends NotificationDtoWithFromUser {
  type: NotificationType.LIKE;
  referenceType: ReferenceType;
  referenceId: number;
}

export interface LikePostNotificationDto extends LikeNotificationDto {
  referenceType: ReferenceType.POST;
  post: {
    id: number;
    title: string;
    image: string | null;
  };
}

export interface LikeReplyNotificationDto extends LikeNotificationDto {
  referenceType: ReferenceType.REPLY;
  post: {
    id: number;
    title: string;
    image: string | null;
  };
  reply: {
    id: number;
    content: string;
  };
}

export interface FollowNotification extends NotificationDtoWithFromUser {
  type: NotificationType.FOLLOW;
}

interface ReplyNotificationDto extends NotificationDtoWithFromUser {
  type: NotificationType.REPLY;
  content: string;
  referenceType: ReferenceType;
}

export interface ReplyPostNotificationDto extends ReplyNotificationDto {
  referenceType: ReferenceType.POST;
  post: {
    id: number;
    title: string;
    image: string | null;
  };
}

export interface ReplyReplyNotificationDto extends ReplyNotificationDto {
  referenceType: ReferenceType.REPLY;
  post: {
    id: number;
    title: string;
    image: string | null;
  };
  reply: {
    id: number;
    content: string;
  };
}

export type SupportedNotificationType =
  | LikePostNotificationDto
  | LikeReplyNotificationDto
  | FollowNotification
  | ReplyPostNotificationDto
  | ReplyReplyNotificationDto;
