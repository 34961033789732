import { useMutation } from "@tanstack/react-query";
import httpClient from "../../http/httpClient";
import { SelfDto } from "../../types/User";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";

interface ProfileUpdateRequest {
  id: number;
  nickname: string | null;
  biography: string | null;
}

const useProfileUpdate = () => {
  const { mutate, isPending, error } = useMutation<
    Readonly<SelfDto>,
    unknown,
    ProfileUpdateRequest
  >({
    mutationFn: (request) => httpClient.post("/user/profile", request),
    onSuccess: (user) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QueryKey.USER, user.username],
        }),
        queryClient.invalidateQueries({ queryKey: [QueryKey.USER_SELF] }),
      ]);
    },
  });

  return {
    updateProfile: mutate,
    isLoading: isPending,
    error,
  };
};

export default useProfileUpdate;
