import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { PostDto, UserReplyDto } from "../../types/Post";
import httpClient from "../../http/httpClient";
import { QueryKey } from "../../query/queryKey";

export const usePost = (postId: number) => {
  const { data, isLoading } = useQuery<PostDto>({
    queryKey: [QueryKey.POST, postId],
    queryFn: () => httpClient.get(`/post/details/${postId}`),
    gcTime: 1200000,
    staleTime: 600000,
    retry: 0,
  });

  return {
    post: data ?? null,
    isLoading,
  };
};

export const usePostReplies = (postId: number) => {
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery<ReadonlyArray<UserReplyDto>>({
      queryKey: [QueryKey.POST_REPLIES, postId],
      queryFn: ({ pageParam }) =>
        httpClient.get(`/post/replies/${postId}?offset=${pageParam}`),
      initialPageParam: "",
      getNextPageParam: (lastPage) =>
        lastPage.length == 10 ? lastPage[lastPage.length - 1].id : null,
      gcTime: 1200000,
      staleTime: 600000,
      retry: 0,
    });

  return {
    replies: data ? data.pages.flatMap((page) => page) : [],
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};
