import { Link } from "react-router-dom";
import Text from "./Text";
import PostImage from "../page/community/PostImage";
import {
  NotificationType,
  SupportedNotificationType,
} from "../types/Notification";
import useUserFollow from "../hook/user/useUserFollow";
import React, { useMemo } from "react";
import { ReferenceType } from "../types/shared";
import Button from "./Button";
import Avatar from "./Avatar";
import { getFormatedDateTime } from "../utils/dateUtils";

const PostBrief = ({
  post,
}: {
  post: {
    id: number;
    title: string;
    image: string | null;
  };
}) => {
  return (
    <Link
      to={`/community/post/${post.id}`}
      className="flex justify-end items-center gap-[10px]"
    >
      {post.image ? (
        <PostImage image={post.image} size={50} postId={post.id} />
      ) : (
        <Text
          type="secondary"
          size="xs"
          className="max-w-[100px] line-clamp-2 text-ellipsis"
        >
          {post.title}
        </Text>
      )}
    </Link>
  );
};

const Notification = ({
  notification,
}: {
  notification: SupportedNotificationType;
}) => {
  const { fromUser } = notification;
  const { followUser, isLoading: following } = useUserFollow(true);
  const message = useMemo(() => {
    if (notification.type === NotificationType.FOLLOW) {
      return "started following you.";
    } else if (notification.type === NotificationType.LIKE) {
      if (notification.referenceType === ReferenceType.POST) {
        return "liked your post.";
      } else if (notification.referenceType === ReferenceType.REPLY) {
        return "liked your reply.";
      }
    } else if (notification.type === NotificationType.REPLY) {
      return `replied to your ${
        notification.referenceType === ReferenceType.POST ? "post" : "reply"
      }.`;
    }
  }, [notification]);
  const action = useMemo(() => {
    if (notification.type === NotificationType.FOLLOW) {
      return notification.fromUser.followed ? (
        <Button text="Followed" disabled color="secondary" rounded={false} />
      ) : (
        <Button
          text="Follow"
          loading={following}
          onClick={() => followUser(notification.fromUser.username)}
          color="blue"
          rounded={false}
        />
      );
    } else if (notification.type === NotificationType.LIKE) {
      return <PostBrief post={notification.post} />;
    } else if (notification.type === NotificationType.REPLY) {
      return notification.referenceType === ReferenceType.POST ? (
        <PostBrief post={notification.post} />
      ) : (
        <Link
          to={`/community/post/${notification.post.id}`}
          className="flex justify-end items-center gap-[10px]"
        >
          <Text type="secondary" size="xs">
            {notification.reply.content}
          </Text>
        </Link>
      );
    }
  }, [notification]);
  return (
    <div className="relative w-full flex items-center gap-[30px]">
      {!notification.read && (
        <div className="absolute left-0 top-0 w-[10px] h-[10px] rounded-[5px] bg-red z-[10]" />
      )}
      <div className="main flex items-center gap-[10px] grow">
        <Link to={`/profile/${fromUser.username}`} className="block w-fit">
          <Avatar avatar={fromUser.avatar} size={40} />
        </Link>
        <div className="content">
          <Link to={`/profile/${fromUser.username}`}>
            <Text weight="bold" size="sm">
              {fromUser.nickname ?? fromUser.username}
            </Text>
          </Link>{" "}
          <Text size="sm">{message}</Text>
          {notification.type === NotificationType.REPLY && (
            <Text
              type="secondary"
              size="sm"
              inline={false}
              className="mt-[-2px] line-clamp-1 text-ellipsis"
            >
              {notification.content}
            </Text>
          )}
          <Text type="secondary" size="xs" inline={false}>
            {getFormatedDateTime(notification.createdAt)}
          </Text>
        </div>
      </div>
      <div className="actions flex justify-end gap-[10px]">{action}</div>
    </div>
  );
};

export default Notification;
