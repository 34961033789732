import React, { useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import useOrders from "../hook/order/useOrders";
import OrderCard from "./orders/OrderCard";
import MainBox from "../component/MainBox";
import { Order, OrderStatus } from "../types/Order";
import Text from "../component/Text";
import { Collapse } from "@mui/material";
import CreateEditOrderModal from "./orders/CreateEditOrderModal";
import Popup from "../component/Popup";
import { PiSortAscendingLight } from "react-icons/pi";
import Placeholder from "../component/Placeholder";
import Loading from "../component/Loading";

type SortableField =
  | "name"
  | "nameDesc"
  | "release"
  | "releaseDesc"
  | "balance"
  | "balanceDesc";

const OrderCardGroup = ({
  title,
  orders,
  sort,
}: {
  title: string;
  orders: Array<Order>;
  sort: SortableField;
}) => {
  const [collapse, setCollapse] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div className="flex flex-col gap-[5px]">
      <div className="flex items-center justify-between">
        <Text size="sm" type="secondary">
          {title}
        </Text>
        <div
          className={`py-[3px] cursor-pointer text-secondary ${
            collapse ? "rotate-0" : "rotate-180"
          } transition-rotate duration-300`}
          onClick={() => setCollapse(!collapse)}
        >
          <FaChevronDown />
        </div>
      </div>
      <Collapse in={!collapse}>
        <div
          ref={ref}
          className="cards w-full grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[10px]"
        >
          {orders.sort(sortingFuncs[sort].fn).map((order) => {
            return <OrderCard key={order.id} order={order} />;
          })}
        </div>
      </Collapse>
    </div>
  );
};

const sortingFuncs: Record<
  SortableField,
  {
    text: string;
    fn: (o1: Order, o2: Order) => number;
  }
> = {
  name: {
    text: "Name (A-Z)",
    fn: (o1: Order, o2: Order) => o1.name.localeCompare(o2.name),
  },
  nameDesc: {
    text: "Name (Z-A)",
    fn: (o1: Order, o2: Order) => o2.name.localeCompare(o1.name),
  },
  release: {
    text: "Release (Earlier - Later)",
    fn: (o1: Order, o2: Order) =>
      o1.releaseFromDate.localeCompare(o2.releaseFromDate),
  },
  releaseDesc: {
    text: "Release (Later - Earlier)",
    fn: (o1: Order, o2: Order) =>
      o2.releaseFromDate.localeCompare(o1.releaseFromDate),
  },
  balance: {
    text: "Balance (Lower - Higher)",
    fn: (o1: Order, o2: Order) => o1.balance - o2.balance,
  },
  balanceDesc: {
    text: "Balance (Higher - Lower)",
    fn: (o1: Order, o2: Order) => o2.balance - o1.balance,
  },
};

const Orders = () => {
  const { orders, isLoading } = useOrders();
  const unsettledOrders = orders.filter(
    (order) => order.status === OrderStatus.PENDING
  );
  const settledOrders = orders.filter(
    (order) => order.status === OrderStatus.COMPLETED
  );

  const [openSort, setOpenSort] = useState(false);
  const [sort, setSort] = useState<SortableField>("name");
  return (
    <MainBox className="orders py-[10px] flex flex-col gap-[10px]">
      <div className="w-full flex justify-between">
        <div className="view-mode"></div>
        <div className="actions flex items-center gap-[5px]">
          <Popup
            trigger={
              <PiSortAscendingLight className="text-primary mobile:text-[28px] text-[24px]" />
            }
            open={openSort}
            setOpen={setOpenSort}
          >
            <div className="rounded-[6px] bg-white">
              {Object.entries(sortingFuncs).map((entry) => {
                const [key, value] = entry;
                return (
                  <div
                    className="item w-full px-[15px] py-[10px] cursor-pointer"
                    key={key}
                    onClick={() => {
                      setSort(key as SortableField);
                      setOpenSort(false);
                    }}
                  >
                    <Text size="sm">Sort by {value.text}</Text>
                  </div>
                );
              })}
            </div>
          </Popup>
          <CreateEditOrderModal action="CREATE" />
        </div>
      </div>
      {isLoading ? (
        <div className="w-full h-[300px] flex justify-center items-center gap-[10px]">
          <Loading color="secondary" size="18px" />
          <Text type="secondary" size="sm">
            Loading orders...
          </Text>
        </div>
      ) : !orders.length ? (
        <Placeholder text='Click "Create" to start adding your first order' />
      ) : (
        <div className="flex flex-col gap-[10px]">
          <OrderCardGroup
            title="Unsettled"
            orders={unsettledOrders}
            sort={sort}
          />
          <OrderCardGroup title="Settled" orders={settledOrders} sort={sort} />
        </div>
      )}
    </MainBox>
  );
};

export default Orders;
