import React from "react";
import Root from "./navigation/Root";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "./query/queryClient";
import { RecoilRoot } from "recoil";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { NotificationProvider, Notifications } from "react-easy-notification";
import { StompSessionProvider } from "react-stomp-hooks";
import AppLauncher from "./AppLauncher";

function App() {
  return (
    <RecoilRoot>
      <StompSessionProvider
        url={`${
          process.env.REACT_APP_API_URL ?? "https://localhost:8443"
        }/ws-endpoint`}
      >
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <QueryClientProvider client={queryClient}>
            <AppLauncher>
              <NotificationProvider>
                <Root />
                <Notifications timeout={5000} position="bottomCenter" />
              </NotificationProvider>
            </AppLauncher>
          </QueryClientProvider>
        </LocalizationProvider>
      </StompSessionProvider>
    </RecoilRoot>
  );
}

export default App;
