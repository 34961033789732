import React, { ReactNode } from "react";
import Loading from "../../component/Loading";
import Text from "../../component/Text";

const Action = ({
  onClick,
  icon,
  text,
  authenticated,
  loading = false,
}: {
  onClick?: () => void;
  icon: ReactNode;
  text?: string;
  authenticated: boolean;
  loading?: boolean;
}) => {
  return (
    <div
      className={`flex items-center gap-[5px] ${
        authenticated ? "cursor-pointer hover:opacity-70" : ""
      } transition-opacity duration-300`}
      onClick={() => {
        if (authenticated) onClick?.();
      }}
    >
      {loading ? (
        <Loading color="secondary" size="14px" />
      ) : (
        <Text type="secondary" size="base">
          {icon}
        </Text>
      )}
      {text && (
        <Text type="secondary" size="xs">
          {text}
        </Text>
      )}
    </div>
  );
};

export default Action;
