import { Currency } from "../../types/shared";
import { useMutation } from "@tanstack/react-query";
import { Collectible } from "../../types/Collectible";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import { useNotification } from "react-easy-notification";

interface CreateCollectibleRequest {
  name: string;
  brand: string;
  currency: Currency;
  price: number;
  public: boolean;
  addedAt: Date;
  comment: string | null;
}

const useCreateCollectible = () => {
  const { pushNotification } = useNotification();
  const { mutate, isPending, error } = useMutation<
    Readonly<Collectible>,
    unknown,
    [CreateCollectibleRequest, File | null]
  >({
    mutationFn: (collectible) =>
      httpClient.post<CreateCollectibleRequest, Readonly<Collectible>>(
        "/collectible/create",
        collectible[0],
        collectible[1] ?? new File([], "")
      ),
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QueryKey.COLLECTIBLES] })
        .then(() => {
          pushNotification({
            text: "Successfully created the collectible.",
            type: "success",
          });
        });
    },
    onError: () => {
      pushNotification({
        text: "Failed to create the collectible.",
        type: "danger",
      });
    },
  });

  return {
    createCollectible: mutate,
    isLoading: isPending,
    error,
  };
};

export default useCreateCollectible;
