import { ReactNode, useEffect, useState } from "react";
import { useSubscription } from "react-stomp-hooks";
import useSelf from "./hook/user/useSelf";
import queryClient from "./query/queryClient";
import { QueryKey } from "./query/queryKey";
import { SelfDto } from "./types/User";
import { useRecoilState } from "recoil";
import {
  hasNewNotificationState,
  newNotificationCountState,
} from "./state/notificationState";
import useAppInitialState from "./hook/useAppInitialState";
import { isAppReadyState } from "./state/appState";
import LogoIcon from "./assets/LogoIcon.png";
import Loading from "./component/Loading";
import useNotificationCount from "./hook/notification/useNotificationCount";

const AppLoading = () => {
  const [isReady, _] = useRecoilState(isAppReadyState);
  const [shouldHide, setShouldHide] = useState(false);
  useEffect(() => {
    if (isReady) {
      setTimeout(() => {
        setShouldHide(true);
      }, 350);
    }
  }, [isReady]);

  return (
    <div
      className={`fixed top-0 left-0 z-[99] ${
        shouldHide ? "hidden" : "flex"
      } justify-center items-center w-[100vw] h-screen supports-h-dvh:h-dvh bg-white ${
        isReady ? "opacity-0" : ""
      }  transition-opacity duration-300`}
    >
      <Loading size="110px" />
      <div
        className="absolute w-[80px] aspect-1/1 bg-contain"
        style={{
          backgroundImage: `url(${LogoIcon})`,
        }}
      />
    </div>
  );
};

const UnauthedAppLauncher = ({ children }: { children: ReactNode }) => {
  return <div>{children}</div>;
};

const AuthedAppLauncher = ({
  children,
  self,
}: {
  children: ReactNode;
  self: SelfDto;
}) => {
  const { initialState, loadingInitialState } = useAppInitialState(true);
  const { count, loadingCount } = useNotificationCount();
  const [_hasNewNotification, setHasNewNotification] = useRecoilState(
    hasNewNotificationState
  );
  const [_newNotificationCount, setNewNotificationCount] = useRecoilState(
    newNotificationCountState
  );
  // Check if there is any new notification when the page is loaded
  useEffect(() => {
    if (!loadingInitialState && initialState) {
      setHasNewNotification(initialState.newNotificationCount > 0);
      setNewNotificationCount(initialState.newNotificationCount);
    }
  }, [initialState, loadingInitialState]);
  useEffect(() => {
    if (!loadingCount && count !== null) {
      setNewNotificationCount(count);
    }
  }, [count, loadingCount]);
  // Notification listener
  useSubscription(`/topic/notification/${self?.username}`, (message) => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.NOTIFICATIONS] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.NOTIFICATION_COUNT] });
  });
  return <div>{children}</div>;
};

const AppLauncher = ({ children }: { children: ReactNode }) => {
  const { self, isLoading } = useSelf();
  // setIsReady can be used independently in the authed/unauthed launchers
  const [_, setIsReady] = useRecoilState(isAppReadyState);
  useEffect(() => {
    // TODO: Remove hard coded loading time. It should be larger than 2 seconds while still waiting for all data loading to finish
    setTimeout(() => {
      setIsReady(true);
    }, 2000);
  }, []);
  return (
    <>
      <AppLoading />
      {!!self ? (
        <AuthedAppLauncher self={self}>{children}</AuthedAppLauncher>
      ) : (
        <UnauthedAppLauncher>{children}</UnauthedAppLauncher>
      )}
    </>
  );
};

export default AppLauncher;
