import { Currency } from "../../types/shared";
import { Order, OrderReleaseType } from "../../types/Order";
import { useMutation } from "@tanstack/react-query";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import { useNotification } from "react-easy-notification";

interface CreateOrderRequest {
  name: string;
  brand: string;
  currency: Currency;
  deposit: number;
  balance: number;
  releaseType: OrderReleaseType;
  releaseFromDate: Date;
  releaseToDate: Date | null;
  comment: string | null;
}

const useCreateOrder = () => {
  const { pushNotification } = useNotification();
  const { mutate, isPending, error } = useMutation<
    Readonly<Order>,
    unknown,
    [CreateOrderRequest, File | null]
  >({
    mutationFn: (request) =>
      httpClient.post<CreateOrderRequest, Readonly<Order>>(
        "/order/create",
        request[0],
        request[1] ?? new File([], "")
      ),
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QueryKey.ORDERS] })
        .then(() => {
          pushNotification({
            text: "Successfully created the order.",
            type: "success",
          });
        });
    },
    onError: () => {
      pushNotification({
        text: "Failed to create the order.",
        type: "danger",
      });
    },
  });

  return {
    isLoading: isPending,
    error,
    createOrder: mutate,
  };
};

export default useCreateOrder;
