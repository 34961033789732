import React, { useMemo, useRef } from "react";
import Text from "./Text";
import { AiOutlineClose } from "react-icons/ai";

interface FileUploadProps {
  file: File | null;
  onDelete?: () => void;
  type: "image";
  placeholder?: string;
}

interface SingleFileUploadProps extends FileUploadProps {
  onChange: (file: File | null) => void;
  multiple: false;
}

interface MultipleFilesUploadProps extends FileUploadProps {
  multiple: true;
  limit: number;
  onChange: (files: File[]) => void;
}

const FileUpload: React.FC<SingleFileUploadProps | MultipleFilesUploadProps> = (
  props
) => {
  const { placeholder, type, file, onDelete } = props;
  const ref = useRef<HTMLInputElement>(null);
  const imageUrl = useMemo(() => {
    return file ? URL.createObjectURL(file) : null;
  }, [file]);
  return (
    <div className="w-full h-full relative">
      {onDelete && (
        <div
          className="absolute right-[5px] top-[5px] z-1 bg-primary opacity-50 w-[20px] h-[20px] rounded-[10px] flex justify-center items-center cursor-pointer"
          onClick={onDelete}
        >
          <Text type="white">
            <AiOutlineClose />
          </Text>
        </div>
      )}
      <div
        className="w-full h-full flex justify-center items-center cursor-pointer"
        onClick={() => {
          ref.current?.click();
        }}
      >
        {!file && (
          <Text type="secondary" size="sm" wrap="prewrap">
            {placeholder ?? "Upload Image"}
          </Text>
        )}
        {file && type == "image" && imageUrl && (
          <img
            alt="Order PostImage"
            className="w-full h-full object-cover"
            src={imageUrl}
          />
        )}
        <input
          ref={ref}
          className="w-full h-full"
          type="file"
          hidden
          accept="image/png, image/jpeg"
          multiple={"limit" in props}
          onChange={(event) => {
            if ("limit" in props) {
              if (event.target.files) {
                const files: File[] = [];
                for (
                  let i = 0;
                  i < Math.min(props.limit, event.target.files.length);
                  i += 1
                ) {
                  files.push(event.target.files[i]);
                }
                props.onChange(files);
              }
            } else {
              props.onChange(event.target.files?.[0] ?? null);
            }
          }}
        />
      </div>
    </div>
  );
};

export default FileUpload;
