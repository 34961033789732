import Loading from "../../component/Loading";
import Text from "../../component/Text";
import PostCard from "./PostCard";
import Button from "../../component/Button";
import { PostDto } from "../../types/Post";
import React from "react";

interface PostListProps {
  from: "LATEST" | "FOLLOWING";
  isLoading: boolean;
  posts: ReadonlyArray<PostDto>;
  canLoadMore: boolean;
  loadingMore: boolean;
  loadMore: () => void;
}

const PostList: React.FC<PostListProps> = ({
  from,
  isLoading,
  posts,
  canLoadMore,
  loadingMore,
  loadMore,
}) => {
  return (
    <div className="w-full pb-[60px]">
      {isLoading && (
        <div className="w-full sm:h-[300px] h-[150px] flex justify-center items-center gap-[5px]">
          <Loading color="secondary" size="18px" />
          <Text type="secondary" size="sm">
            Loading posts...
          </Text>
        </div>
      )}
      {!isLoading && !posts.length && (
        <div className="w-full sm:h-[300px] h-[150px] flex justify-center items-center">
          <Text type="secondary" size="sm">
            There is no post yet.
          </Text>
        </div>
      )}
      {!isLoading && posts.length > 0 && (
        <div className="w-full flex flex-col items-center gap-[10px]">
          {posts.map((post) => {
            return <PostCard from={from} key={post.id} post={post} />;
          })}
          {canLoadMore && (
            <Button
              loading={loadingMore}
              type="outline"
              color="secondary"
              text="Load More Posts"
              onClick={loadMore}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PostList;
