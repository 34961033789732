import useSelf from "../../hook/user/useSelf";
import React from "react";
import UserProfile from "./UserProfile";
import useUserProfile from "../../hook/user/useUserProfile";

const SelfProfile = () => {
  const { self } = useSelf();
  const { userProfile } = useUserProfile(self?.username ?? null);
  return self && userProfile ? (
    <UserProfile profile={userProfile} readonly={false} />
  ) : null;
};

export default SelfProfile;
