import React from "react";
import Text from "../component/Text";
import { Link, NavLink, useLocation } from "react-router-dom";
import TextLink from "../component/TextLink";
import useSelf from "../hook/user/useSelf";
import MainBox from "../component/MainBox";
import { SelfDto } from "../types/User";
import { CgMenuGridO } from "react-icons/cg";
import { useRecoilState } from "recoil";
import { showMobileMenuState } from "../state/headerState";
import Logo from "../component/Logo";
import Avatar from "../component/Avatar";
import { RiNotification3Fill } from "react-icons/ri";
import {
  newNotificationCountState,
  showNotificationsState,
} from "../state/notificationState";

const Tab: React.FC<{
  text: string;
  link: string;
}> = ({ text, link }) => {
  const baseClassName =
    "cursor-pointer md:w-[90px] w-[60px] h-[30px] flex justify-center items-center transition-all duration-200 hover:opacity-60";
  return (
    <NavLink
      to={link}
      className={({ isActive }) =>
        `${baseClassName} ${isActive ? "font-bold tracking-tight" : ""}`
      }
    >
      <Text>{text}</Text>
    </NavLink>
  );
};

const AuthenticatedTab = ({
  user,
  text,
  link,
}: {
  user: Readonly<SelfDto> | null;
  text: string;
  link: string;
}) => {
  if (!user) return null;
  return <Tab text={text} link={link} />;
};

const SignInSignOut = () => {
  return (
    <div className="sm:flex hidden gap-[5px] items-center">
      <TextLink to="/signin" size="sm" type="secondary">
        Sign In
      </TextLink>
      <Text size="sm" type="secondary">
        /
      </Text>
      <TextLink to="/signup" size="sm" type="secondary">
        Sign Up
      </TextLink>
    </div>
  );
};

const UserProfile = ({ user }: { user: Readonly<SelfDto> | null }) => {
  return (
    <Link to="/profile">
      <Avatar avatar={user?.avatar ?? null} size={40} />
    </Link>
  );
};

const Profile = ({ isAtSignInSignUp }: { isAtSignInSignUp: boolean }) => {
  const { self } = useSelf();
  const [showNotifications, setShowNotifications] = useRecoilState(
    showNotificationsState
  );
  const [newNotificationCount] = useRecoilState(newNotificationCountState);
  let onClickNotification = () => {
    // TODO: Fix to close notification when notifications is open and clicking the icon
    if (!showNotifications) {
      setShowNotifications(true);
    }
  };
  return (
    <div className="user absolute sm:right-[10px] right-0 flex gap-[5px] items-center">
      {!self && !isAtSignInSignUp && <SignInSignOut />}
      {!!self && (
        <div
          className="relative mr-[10px] cursor-pointer"
          onMouseUp={onClickNotification}
        >
          {newNotificationCount > 0 && (
            <div
              className="absolute top-[-5px] flex justify-center items-center bg-red min-w-[20px] px-[5px] h-[14px] rounded-[7px] text-white text-[10px]"
              style={{
                left: "calc(100% - 12px)",
              }}
            >
              {newNotificationCount}
            </div>
          )}
          <RiNotification3Fill className="text-primary" size="24px" />
        </div>
      )}
      <UserProfile user={self} />
      <MobileMenu />
    </div>
  );
};

const MobileMenu = () => {
  const [showMenu, setShowMenu] = useRecoilState(showMobileMenuState);
  return (
    <div className="sm:hidden block text-primary">
      <CgMenuGridO size="36px" onClick={() => setShowMenu(!showMenu)} />
    </div>
  );
};

const Header = () => {
  const location = useLocation();
  const { self } = useSelf();
  const isSignInSignUp =
    location.pathname.includes("/signin") ||
    location.pathname.includes("/signup");
  return (
    <div className="header top-0 left-0 right-0 w-full bg-white flex justify-center min-h-[80px] h-[80px] z-10 shadow-header">
      <MainBox className="content h-full relative flex justify-center items-center">
        <Logo link className="absolute sm:left-[10px] left-0" />
        {!isSignInSignUp && (
          <div className="tabs relative md:left-0 left-[30px] sm:flex hidden gap-[30px] justify-center">
            <Tab link="/news" text="News" />
            <Tab link="/community" text="Community" />
            <AuthenticatedTab user={self} link="/orders" text="Orders" />
            <AuthenticatedTab
              user={self}
              link="/collection"
              text="Collection"
            />
          </div>
        )}
        <Profile isAtSignInSignUp={isSignInSignUp} />
      </MainBox>
    </div>
  );
};

export default Header;
