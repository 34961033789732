import { Collectible } from "../../types/Collectible";
import React, { useState } from "react";
import useDeleteCollectible from "../../hook/collectible/useDeleteCollectible";
import Modal from "../../component/Modal";
import Text from "../../component/Text";
import { FaRegTrashAlt } from "react-icons/fa";
import Button from "../../component/Button";

interface DeleteCollectibleModalProps {
  collectible: Collectible;
  onOpen: () => void;
  onClose: () => void;
}

const DeleteCollectibleModal: React.FC<DeleteCollectibleModalProps> = ({
  collectible,
  onClose,
  onOpen,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const { deleteCollectible, isLoading } = useDeleteCollectible();

  return (
    <Modal
      title="Delete Collectible"
      open={open}
      onOpen={() => {
        setOpen(true);
        onOpen();
      }}
      showClose={false}
      trigger={
        <Text type="white" pointer>
          <FaRegTrashAlt className="mobile:text-[16px] text-[18px]" />
        </Text>
      }
    >
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-col items-center gap-[3px]">
          <Text inline={false} size="sm">
            Are you sure you want to delete this order?
          </Text>
          <Text inline={false} size="sm">
            This cannot be undo.
          </Text>
        </div>
        {error && (
          <div className="w-full flex justify-center">
            <Text type="danger" size="xs">
              {error}
            </Text>
          </div>
        )}
        <div className="w-full flex justify-center gap-[10px]">
          <Button
            type="outline"
            color="red"
            text="Yes, I do"
            loading={isLoading}
            onClick={() => {
              deleteCollectible(collectible.id, {
                onSuccess: () => {
                  setOpen(false);
                  onClose();
                },
                onError: (error) => {
                  if (error instanceof Error) {
                    setError(error.message);
                  } else {
                    setError("Failed to delete the collectible.");
                  }
                },
              });
            }}
          />
          <Button
            type="outline"
            color="yellow"
            text="No, I do not"
            disabled={isLoading}
            onClick={() => {
              setOpen(false);
              setError("");
              onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteCollectibleModal;
