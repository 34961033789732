import useCollectibles from "../../hook/collectible/useCollectibles";
import ExpandableCollectibleCard from "./ExpandableCollectibleCard";
import MainBox from "../../component/MainBox";
import CreateEditCollectibleModal from "./CreateEditCollectibleModal";
import { Collectible } from "../../types/Collectible";
import Text from "../../component/Text";
import { FaChevronDown } from "react-icons/fa6";
import React, { useState } from "react";
import { Collapse } from "@mui/material";
import Switch from "../../component/Switch";
import { PiSortAscendingLight } from "react-icons/pi";
import Popup from "../../component/Popup";
import Placeholder from "../../component/Placeholder";
import Loading from "../../component/Loading";

type SortableField =
  | "name"
  | "nameDesc"
  | "price"
  | "priceDesc"
  | "addedAt"
  | "addedAtDesc";

const sortingFuncs: Record<
  SortableField,
  {
    text: string;
    fn: (c1: Collectible, c2: Collectible) => number;
  }
> = {
  name: {
    text: "Name (A-Z)",
    fn: (c1: Collectible, c2: Collectible) => c1.name.localeCompare(c2.name),
  },
  nameDesc: {
    text: "Name (Z-A)",
    fn: (c1: Collectible, c2: Collectible) => c2.name.localeCompare(c1.name),
  },
  addedAt: {
    text: "Added Date (Earlier - Later)",
    fn: (c1: Collectible, c2: Collectible) =>
      c1.addedAt.localeCompare(c2.addedAt),
  },
  addedAtDesc: {
    text: "Added Date (Later - Earlier)",
    fn: (c1: Collectible, c2: Collectible) =>
      c2.addedAt.localeCompare(c1.addedAt),
  },
  price: {
    text: "Price (Lower - Higher)",
    fn: (c1: Collectible, c2: Collectible) => c1.price - c2.price,
  },
  priceDesc: {
    text: "Price (Higher - Lower)",
    fn: (c1: Collectible, c2: Collectible) => c2.price - c1.price,
  },
};

const CollectibleGroup = ({
  collectibles,
  brand,
  sort,
}: {
  collectibles: Array<Collectible>;
  brand?: string;
  sort: SortableField;
}) => {
  const [collapse, setCollapse] = useState(false);
  return (
    <div className="w-full">
      {brand && (
        <div className="flex items-center justify-between">
          <Text size="sm" type="secondary">
            {brand}
          </Text>
          <div
            className={`py-[3px] cursor-pointer text-secondary ${
              collapse ? "rotate-0" : "rotate-180"
            } transition-rotate duration-300`}
            onClick={() => setCollapse(!collapse)}
          >
            <FaChevronDown />
          </div>
        </div>
      )}
      <Collapse in={!collapse}>
        <div className="cards w-full grid lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 mobile:grid-cols-3 grid-cols-2 gap-[10px]">
          {collectibles.sort(sortingFuncs[sort].fn).map((collectible) => {
            return (
              <ExpandableCollectibleCard
                key={collectible.id}
                collectible={collectible}
              />
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

const Collection = () => {
  const { collectibles, isLoading } = useCollectibles();
  const collectiblesByBrand = collectibles.reduce<
    Record<string, Array<Collectible>>
  >((map, collectible) => {
    if (!map[collectible.brand]) map[collectible.brand] = [collectible];
    else {
      map[collectible.brand].push(collectible);
    }
    return map;
  }, {});
  const [groupByBrand, setGroupByBrand] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [sort, setSort] = useState<SortableField>("name");
  return (
    <MainBox className="collection py-[10px] flex flex-col gap-[10px]">
      <div className="w-full flex justify-between">
        <div className="view-mode"></div>
        <div className="actions flex items-end gap-[5px]">
          <Switch
            title="Group by Brands"
            style="dark"
            value={groupByBrand}
            onChange={setGroupByBrand}
          />
          <div className="relative top-[-5px]">
            <Popup
              trigger={
                <PiSortAscendingLight className="text-primary" size="28px" />
              }
              open={openSort}
              setOpen={setOpenSort}
            >
              <div className="rounded-[6px] bg-white">
                {Object.entries(sortingFuncs).map((entry) => {
                  const [key, value] = entry;
                  return (
                    <div
                      className="item w-full px-[15px] py-[10px] cursor-pointer"
                      key={key}
                      onClick={() => {
                        setSort(key as SortableField);
                        setOpenSort(false);
                      }}
                    >
                      <Text size="sm">Sort by {value.text}</Text>
                    </div>
                  );
                })}
              </div>
            </Popup>
          </div>
          <CreateEditCollectibleModal
            action="CREATE"
            onOpen={() => {}}
            onClose={() => {}}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="w-full h-[300px] flex justify-center items-center gap-[10px]">
          <Loading color="secondary" size="18px" />
          <Text type="secondary" size="sm">
            Loading your collection...
          </Text>
        </div>
      ) : !collectibles.length ? (
        <Placeholder text='Click "Create" to start adding your first collectible' />
      ) : groupByBrand ? (
        Object.entries(collectiblesByBrand)
          .sort((e1, e2) => e1[0].localeCompare(e2[0]))
          .map((entry) => {
            return (
              <CollectibleGroup
                key={entry[0]}
                sort={sort}
                brand={entry[0]}
                collectibles={entry[1]}
              />
            );
          })
      ) : (
        <CollectibleGroup sort={sort} collectibles={collectibles} />
      )}
    </MainBox>
  );
};

export default Collection;
