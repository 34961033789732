import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { SupportedNotificationType } from "../types/Notification";
import { QueryKey } from "../query/queryKey";
import httpClient from "../http/httpClient";
import queryClient from "../query/queryClient";

const useNotifications = (enabled: boolean) => {
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery<ReadonlyArray<SupportedNotificationType>>({
      queryKey: [QueryKey.NOTIFICATIONS],
      queryFn: ({ pageParam }) =>
        httpClient.get(`/notification/all?offset=${pageParam}`),
      initialPageParam: "",
      getNextPageParam: (lastPage) =>
        lastPage.length != 0 ? lastPage[lastPage.length - 1].id : null,
      gcTime: 600000,
      staleTime: 300000,
      retry: 0,
      enabled,
    });

  const readAllNotifications = () => {
    const data = queryClient.getQueryData<
      InfiniteData<SupportedNotificationType[]>
    >([QueryKey.NOTIFICATIONS]);
    if (!data) return;

    data.pages.forEach((notifications) => {
      notifications.forEach((notification) => {
        notification.read = true;
      });
    });

    queryClient.setQueryData([QueryKey.NOTIFICATIONS], data);
  };

  return {
    notifications: data ? data.pages.flatMap((page) => page) : [],
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    readAllNotifications,
  };
};

export default useNotifications;
