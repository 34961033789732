import useFollows from "../../hook/user/useFollows";
import { UserDto } from "../../types/User";
import React, { useEffect, useState } from "react";
import Modal from "../../component/Modal";
import Text from "../../component/Text";
import Avatar from "../../component/Avatar";
import Button from "../../component/Button";
import useSelf from "../../hook/user/useSelf";
import useUserFollow from "../../hook/user/useUserFollow";
import { useNavigate } from "react-router-dom";
import Loading from "../../component/Loading";

interface FollowingUsersModalProps {
  user: UserDto;
  type: "FOLLOWING" | "FOLLOWER";
  count: number;
}

const FollowUser = ({ user }: { user: UserDto }) => {
  const { self } = useSelf();
  const { followUser, isLoading: following } = useUserFollow(true);
  const { followUser: unfollowUser, isLoading: unfollowing } =
    useUserFollow(false);
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-between px-[6px] py-[8px] hover:bg-almostwhite rounded-[8px] transition-bg duration-300">
      <div
        className="flex items-center gap-[10px] cursor-pointer grow"
        onClick={() => {
          navigate(`/profile/${user.username}`);
        }}
      >
        <Avatar avatar={user.avatar} size={36} />
        <Text>{user.nickname ?? user.username}</Text>
      </div>
      {self && self?.id !== user.id && (
        <Button
          size="sm"
          loading={following || unfollowing}
          color={user.followed ? "red" : "blue"}
          text={user.followed ? "Unfollow" : "Follow"}
          onClick={() => {
            if (user.followed) {
              unfollowUser(user.username);
            } else {
              followUser(user.username);
            }
          }}
        />
      )}
    </div>
  );
};

const FollowModal: React.FC<FollowingUsersModalProps> = ({
  user,
  type,
  count,
}) => {
  const [open, setOpen] = useState(false);
  const { follows, isLoading } = useFollows(user.username, type, open);
  const isFollowing = type === "FOLLOWING";
  useEffect(() => {
    setOpen(false);
  }, [user]);
  return (
    <Modal
      trigger={
        <div className="following flex gap-[3px] cursor-pointer hover:opacity-60 transition-opacity duration-100">
          <Text size="sm">{count}</Text>
          <Text type="secondary" size="sm">
            {isFollowing ? "Following" : "Followers"}
          </Text>
        </div>
      }
      maxHeight={500}
      minHeight={400}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      title={`${isFollowing ? "Following" : "Followers"} (${follows.length})`}
    >
      <div className="flex flex-col gap-[3px]">
        {isLoading && (
          <div className="w-full h-[300px] flex justify-center items-center">
            <Loading color="secondary" size="28px" />
          </div>
        )}
        {!follows.length && (
          <div className="w-full h-[300px] flex justify-center items-center">
            <Text type="secondary" size="sm">
              No {isFollowing ? "follower" : "collector is being followed"} yet
            </Text>
          </div>
        )}
        {follows.map((follow) => (
          <FollowUser key={follow.id} user={follow} />
        ))}
      </div>
    </Modal>
  );
};

export default FollowModal;
