import { Order } from "../../types/Order";
import React, { useState } from "react";
import Modal from "../../component/Modal";
import { FaRegTrashAlt } from "react-icons/fa";
import Text from "../../component/Text";
import Button from "../../component/Button";
import useDeleteOrder from "../../hook/order/useDeleteOrder";

interface DeleteOrderModalProps {
  order: Order;
}

const DeleteOrderModal: React.FC<DeleteOrderModalProps> = ({ order }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const { deleteOrder, isLoading } = useDeleteOrder();
  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={
        <Text type="secondary" pointer onClick={() => {}}>
          <FaRegTrashAlt className="mobile:text-[16px] text-[18px]" />
        </Text>
      }
      title="Delete Order"
      showClose={false}
    >
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-col items-center gap-[3px]">
          <Text inline={false} size="sm">
            Are you sure you want to delete this order?
          </Text>
          <Text inline={false} size="sm">
            This cannot be undo.
          </Text>
        </div>
        {error && (
          <div className="w-full flex justify-center">
            <Text type="danger" size="xs">
              {error}
            </Text>
          </div>
        )}
        <div className="w-full flex justify-center gap-[10px]">
          <Button
            type="outline"
            color="red"
            text="Yes, I do"
            loading={isLoading}
            onClick={() => {
              deleteOrder(order.id, {
                onSuccess: () => {
                  setOpen(false);
                },
                onError: (error) => {
                  if (error instanceof Error) {
                    setError(error.message);
                  } else {
                    setError("Failed to delete the order.");
                  }
                },
              });
            }}
          />
          <Button
            type="outline"
            color="yellow"
            text="No, I do not"
            disabled={isLoading}
            onClick={() => {
              setOpen(false);
              setError("");
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteOrderModal;
