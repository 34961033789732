import { useMutation } from "@tanstack/react-query";
import { SelfDto } from "../../types/User";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import { useNotification } from "react-easy-notification";

const useAvatarUpload = () => {
  const { pushNotification } = useNotification();
  const { mutate, isPending, error } = useMutation<
    Readonly<SelfDto>,
    unknown,
    [{ id: number; avatar: string | null }, File]
  >({
    mutationFn: ([request, file]) =>
      httpClient.post(`/user/avatar/${request.id}`, null, file),
    onSuccess: (user, request) => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QueryKey.USER, user.username],
        }),
        queryClient.invalidateQueries({ queryKey: [QueryKey.USER_SELF] }),
      ]).then(() => {
        pushNotification({
          text: "Successfully uploaded the avatar.",
          type: "success",
        });
        return queryClient.removeQueries({
          queryKey: [QueryKey.USER_AVATAR, request[0].avatar],
        });
      });
    },
    onError: () => {
      pushNotification({
        text: "Failed to upload the avatar.",
        type: "danger",
      });
    },
  });

  return {
    uploadAvatar: mutate,
    isLoading: isPending,
    error,
  };
};

export default useAvatarUpload;
