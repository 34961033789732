import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../../query/queryKey";
import httpClient from "../../http/httpClient";

const useAvatar = (avatar: string | null) => {
  const { data, isLoading, error } = useQuery<Blob | null>({
    queryKey: [QueryKey.USER_AVATAR, avatar],
    queryFn: () => {
      return avatar ? httpClient.getFile<Blob>(`/blob/users/${avatar}`) : null;
    },
    gcTime: 1200000,
    staleTime: 600000,
    retry: 0,
  });

  return {
    avatar: data ?? null,
    isLoading,
    error,
  };
};

export default useAvatar;
