import { PostDto } from "../../types/Post";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Text from "../../component/Text";
import AvatarUsername from "../../component/AvatarUsername";
import PostActionGroup from "./PostActionGroup";
import { useRecoilState } from "recoil";
import { postDetailFromState } from "../../state/postDetailFromState";
import PostImage from "./PostImage";

interface PostCardProps {
  from: "LATEST" | "FOLLOWING";
  post: PostDto;
}

const PostCard: React.FC<PostCardProps> = ({ post, from }) => {
  const navigate = useNavigate();
  const [_, setPostDetailsFrom] = useRecoilState(postDetailFromState);

  return (
    <div className="flex flex-col gap-[5px] w-full max-w-full p-[10px] bg-white rounded-[6px]">
      <div className="header w-full flex items-center min-w-0 gap-[30px]">
        <div className="grow whitespace-nowrap overflow-hidden text-ellipsis">
          <Link
            to={`/community/post/${post.id}`}
            onClick={() => setPostDetailsFrom(from)}
          >
            <Text
              size="sm"
              weight="bold"
              className="cursor-pointer hover:underline"
            >
              {post.title}
            </Text>
          </Link>
        </div>
        <div>
          <AvatarUsername user={post.user} />
        </div>
      </div>
      <div
        className={`description ${
          post.images.length ? "line-clamp-1" : "line-clamp-3"
        } text-ellipsis`}
      >
        <Text type="secondary" size="sm">
          {post.content}
        </Text>
      </div>
      {post.images.length > 0 && (
        <div className="images flex gap-[5px]">
          {post.images.map((image) => (
            <PostImage key={image} size={200} image={image} postId={post.id} />
          ))}
        </div>
      )}
      <PostActionGroup
        post={post}
        onReply={() => navigate(`/community/post/${post.id}`)}
      />
    </div>
  );
};

export default PostCard;
