import React from "react";
import Text from "./Text";

interface PlaceholderProps {
  text: string;
}

const Placeholder: React.FC<PlaceholderProps> = ({ text }) => {
  return (
    <div className="w-full h-[300px] flex justify-center items-center">
      <Text type="secondary">{text}</Text>
    </div>
  );
};

export default Placeholder;
