import { useNotification } from "react-easy-notification";
import { useMutation } from "@tanstack/react-query";
import { PostDto } from "../../types/Post";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";

interface CreatePostRequest {
  title: string;
  content: string;
}

const useCreatePost = () => {
  const { pushNotification } = useNotification();

  const { mutate, isPending, error } = useMutation<
    Readonly<PostDto>,
    unknown,
    [CreatePostRequest, File[]]
  >({
    mutationFn: (request) =>
      httpClient.post<CreatePostRequest, Readonly<PostDto>>(
        "/post/create",
        request[0],
        request[1]
      ),
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QueryKey.POSTS_ALL] })
        .then(() => {
          pushNotification({
            text: "Successfully created the post",
            type: "success",
          });
        });
    },
    onError: () => {
      pushNotification({
        text: "Failed to create the post.",
        type: "danger",
      });
    },
  });

  return {
    createPost: mutate,
    isLoading: isPending,
    error,
  };
};

export default useCreatePost;
