export const QueryKey = {
  COLLECTIBLE_IMAGE: "COLLECTIBLE_IMAGE",
  COLLECTIBLES: "COLLECTIBLES",
  FOLLOWINGS: "FOLLOWINGS",
  FOLLOWERS: "FOLLOWERS",
  IMAGE: "IMAGE",
  INITIAL_STATE: "INITIAL_STATE",
  NOTIFICATIONS: "NOTIFICATIONS",
  NOTIFICATION_COUNT: "NOTIFICATION_COUNT",
  ORDER_IMAGE: "ORDER_IMAGE",
  ORDERS: "ORDERS",
  POST: "POST",
  POST_IMAGES: "POST_IMAGES",
  POST_REPLIES: "POST_REPLIES",
  POSTS_ALL: "POSTS_ALL",
  POSTS_ALL_FOLLOWING: "POSTS_ALL_FOLLOWING",
  POSTS_USER: "POSTS_USER",
  REPLY: "REPLY",
  UNFOLLOWED_USERS: "UNFOLLOWED_USERS",
  USER: "USER",
  USER_AVATAR: "USER_AVATAR",
  USER_COLLECTIBLES: "USER_COLLECTIBLES",
  USER_SELF: "USER_SELF",
};
