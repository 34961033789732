import useAllPosts from "../../hook/post/useAllPosts";
import PostList from "./PostList";

const Latest = () => {
  const { posts, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useAllPosts(false);
  return (
    <PostList
      from="LATEST"
      isLoading={isLoading}
      posts={posts}
      canLoadMore={hasNextPage}
      loadingMore={isFetchingNextPage}
      loadMore={fetchNextPage}
    />
  );
};

export default Latest;
