import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import MobileMenuContent from "./MobileMenuContent";
import Notifications from "./Notifications";

const Layout = () => {
  const location = useLocation();
  const isCommunity = location.pathname.includes("/community/");
  const showFooter = !isCommunity;
  return (
    <div className="relative w-full max-h-screen supports-max-h-dvh:max-h-dvh h-screen supports-h-dvh:h-dvh flex flex-col bg-almostwhite overflow-hidden">
      <Header />
      <div className="flex flex-col grow relative overflow-x-hidden">
        <Notifications />
        <div className="min-h-full flex flex-col items-center overflow-y-auto overflow-x-hidden">
          <div className="w-full grow flex justify-center">
            <Outlet />
          </div>
          {showFooter && <Footer />}
        </div>
      </div>
      <MobileMenuContent />
    </div>
  );
};

export default Layout;
