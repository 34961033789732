import { atom } from "recoil";

export const hasNewNotificationState = atom<boolean>({
  key: "hasNewNotification",
  default: false,
});

export const newNotificationCountState = atom<number>({
  key: "newNotificationCount",
  default: 0,
});

export const showNotificationsState = atom<boolean>({
  key: "showNotifications",
  default: false,
});
