import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../../query/queryKey";
import httpClient from "../../http/httpClient";

const useCollectibleImage = (image: string | null) => {
  const { data, isLoading, error } = useQuery<Blob | null>({
    queryKey: [QueryKey.COLLECTIBLE_IMAGE, image],
    queryFn: () => {
      return image
        ? httpClient.getFile<Blob>(`/blob/collectibles/${image}`)
        : null;
    },
    gcTime: 1200000,
    staleTime: 600000,
    retry: 0,
  });

  return {
    image: data ?? null,
    isLoading,
    error,
  };
};

export default useCollectibleImage;
