import { useNotification } from "react-easy-notification";
import { useMutation } from "@tanstack/react-query";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";

const useDeletePost = () => {
  const { pushNotification } = useNotification();
  const { mutate, isPending } = useMutation({
    mutationFn: (postId: number) => httpClient.delete(`/post/delete/${postId}`),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QueryKey.POSTS_ALL],
        })
        .then(() => {
          pushNotification({
            type: "success",
            text: "Successfully deleted the post.",
          });
        });
    },
    onError: () => {
      pushNotification({
        text: "Failed to delete the post.",
        type: "danger",
      });
    },
  });
  return {
    deletePost: mutate,
    deleting: isPending,
  };
};

export default useDeletePost;
