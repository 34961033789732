import { PostDto, UserReplyDto } from "../../types/Post";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import { InfiniteData } from "@tanstack/react-query";

// NOTE: This function will directly update the post object in the cache
export const updatePostBy = (
  postId: number,
  updateFn: (post: PostDto) => void
) => {
  queryClient.invalidateQueries({ queryKey: [QueryKey.POST, postId] });
  const data = queryClient.getQueryData<InfiniteData<PostDto[]>>([
    QueryKey.POSTS_ALL,
  ]);
  if (!data) return;

  let postIndex = -1;

  const pageIndex = data.pages.findIndex((posts) => {
    const index = posts.findIndex((post) => post.id === postId);
    if (index === -1) return false;
    else {
      postIndex = index;
      return true;
    }
  });

  updateFn(data.pages[pageIndex][postIndex]);
  queryClient.setQueryData([QueryKey.POSTS_ALL], data);
};
// NOTE: This function will directly update the reply object in the cache
export const updateReplyBy = (
  replyId: number,
  postId: number,
  inGroup: boolean,
  updateFn: (replyDto: UserReplyDto) => void
) => {
  queryClient.invalidateQueries({ queryKey: [QueryKey.REPLY, replyId] });
  const data = queryClient.getQueryData<InfiniteData<UserReplyDto[]>>([
    QueryKey.POST_REPLIES,
    postId,
  ]);
  if (!data) return;

  data.pages.forEach((replies) => {
    replies.forEach((reply) => {
      if (inGroup) {
        reply.replies.forEach((replyInGroup) => {
          if (replyInGroup.id === replyId) {
            updateFn(replyInGroup);
          }
        });
      } else {
        if (reply.id === replyId) {
          updateFn(reply);
        }
      }
    });
  });

  queryClient.setQueryData([QueryKey.POST_REPLIES, postId], data);
};
