import { useNotification } from "react-easy-notification";
import { useMutation } from "@tanstack/react-query";
import { PostDto } from "../../types/Post";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import useAllPosts from "./useAllPosts";
import { useEffect, useState } from "react";

interface AmendPostRequest {
  id: number;
  title: string;
  content: string;
  images: (string | null)[];
}

const useAmendPost = () => {
  const { pushNotification } = useNotification();
  // One cannot follow themselves so onlyFollow=false is fine
  const { isFetching } = useAllPosts(false);
  const [updateId, setUpdateId] = useState<number | null>(null);
  useEffect(() => {
    if (!isFetching && updateId !== null) {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QueryKey.POST_IMAGES, updateId],
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKey.IMAGE, "post", updateId],
        }),
      ]).then(() => setUpdateId(null));
    }
  }, [isFetching, updateId]);

  const { mutate, isPending, error } = useMutation<
    Readonly<PostDto>,
    unknown,
    [AmendPostRequest, File[]]
  >({
    mutationFn: (request) =>
      httpClient.post<AmendPostRequest, Readonly<PostDto>>(
        "/post/amend",
        request[0],
        request[1]
      ),
    onSuccess: (_, request) => {
      Promise.all([
        queryClient.invalidateQueries({ queryKey: [QueryKey.POSTS_ALL] }),
        queryClient.invalidateQueries({
          queryKey: [QueryKey.POST, request[0].id],
        }),
      ]).then(() => {
        setUpdateId(request[0].id);
        // while (isFetching) {}

        // queryClient.invalidateQueries({
        //   queryKey: [QueryKey.POST_IMAGES, request[0].id],
        // });
        pushNotification({
          text: "Successfully edited the post",
          type: "success",
        });
      });
    },
    onError: () => {
      pushNotification({
        text: "Failed to edit the post.",
        type: "danger",
      });
    },
  });

  return {
    amendPost: mutate,
    isLoading: isPending,
    error,
  };
};

export default useAmendPost;
