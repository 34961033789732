import React, { ReactNode, useEffect } from "react";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

const Popup = ({
  enabled = true,
  trigger,
  children,
  open,
  setOpen,
}: {
  enabled?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  trigger: ReactNode;
  children: ReactNode;
}) => {
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (enabled) {
      setOpen?.(true);
      setAnchor(anchor ? null : event.currentTarget);
    }
  };

  useEffect(() => {
    if (open !== undefined && !open) {
      setAnchor(null);
    }
  }, [open]);

  const shouldOpen = !!anchor;
  const id = open ? "simple-popper" : undefined;

  return (
    <div>
      <div
        aria-describedby={id}
        className={enabled ? "cursor-pointer" : ""}
        onClick={handleClick}
      >
        {trigger}
      </div>
      <BasePopup id={id} open={shouldOpen} anchor={anchor}>
        <ClickAwayListener
          onClickAway={() => {
            if (anchor) {
              setOpen?.(false);
              setAnchor(null);
            }
          }}
        >
          <div className="shadow-popup rounded-[4px]">{children}</div>
        </ClickAwayListener>
      </BasePopup>
    </div>
  );
};

export default Popup;
