import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../../query/queryKey";
import httpClient from "../../http/httpClient";

const useNotificationCount = () => {
  const { data, isLoading } = useQuery<number>({
    queryKey: [QueryKey.NOTIFICATION_COUNT],
    queryFn: () => httpClient.get("/notification/count"),
    gcTime: Infinity,
    staleTime: Infinity,
  });

  return {
    count: data ?? null,
    loadingCount: isLoading,
  };
};

export default useNotificationCount;
