import { MenuItem, Select } from "@mui/material";
import React from "react";
import Text from "./Text";

interface DropdownProps<T> {
  title?: string;
  value: T;
  onChange: (value: T) => void;
  options: ReadonlyArray<{
    text: string;
    value: T;
  }>;
  className?: string;
}

const Dropdown = <T extends any>({
  title,
  value,
  onChange,
  options,
  className,
}: DropdownProps<T>) => {
  return (
    <div className={`flex flex-col ${className}`}>
      {title && (
        <div>
          <Text size="xs" type="secondary">
            {title}
          </Text>
        </div>
      )}
      <Select
        className="dropdown w-full bg-whitegray text-sm text-primary outline-0"
        sx={{
          boxShadow: "none",
          color: "#444444",
          borderRadius: "6px",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          ".MuiInputBase-input": { fontSize: "14px", padding: 0 },
          padding: "8px",
          cursor: "pointer",
        }}
        value={value}
        // @ts-ignore
        onChange={(value) => {
          // @ts-ignore
          onChange(value.target.value);
        }}
      >
        {options.map(({ text, value }) => {
          return (
            // @ts-ignore
            <MenuItem key={value} value={value}>
              <Text size="sm">{text}</Text>
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default Dropdown;
