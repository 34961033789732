import { UserReplyDto } from "../../types/Post";
import React from "react";
import AvatarUsername from "../../component/AvatarUsername";
import Text from "../../component/Text";
import ReplyActionGroup from "./ReplyActionGroup";

interface PostReplyProps {
  reply: UserReplyDto;
  onReply: (reply: UserReplyDto) => void;
}

const ReplyGroup = ({
  replies,
  root,
  onReply,
}: {
  replies: ReadonlyArray<UserReplyDto>;
  root: UserReplyDto;
  onReply: (reply: UserReplyDto) => void;
}) => {
  const repliesById = replies.reduce<Record<number, UserReplyDto>>(
    (map, current) => {
      map[current.id] = current;
      return map;
    },
    {}
  );
  return (
    <div className="w-full bg-almostwhite p-[10px] rounded-[6px]">
      {replies.map((reply, index) => (
        <div key={reply.id} className="w-full">
          <div className="w-full flex flex-col gap-[10px]">
            <AvatarUsername user={reply.user} size="xs" />
            <Text type="secondary" size="xs" wrap="prewrap">
              {!!repliesById[reply.referenceId] && (
                <>
                  <span>Replied to </span>
                  <span className="font-semibold text-primary">
                    {repliesById[reply.referenceId].user.nickname ??
                      repliesById[reply.referenceId].user.username}
                    :{" "}
                  </span>
                </>
              )}
              {reply.content}
            </Text>
            <ReplyActionGroup
              reply={reply}
              postId={root.referenceId}
              inGroup
              onReply={onReply}
            />
          </div>
          {index < replies.length - 1 && (
            <div className="divider w-full h-[1px] bg-whitegray my-[10px]" />
          )}
        </div>
      ))}
    </div>
  );
};

const PostReply: React.FC<PostReplyProps> = ({ reply, onReply }) => {
  return (
    <div className="reply flex flex-col gap-[10px] p-[10px] border-t-[1px] border-whitegray">
      <AvatarUsername user={reply.user} />
      <Text type="secondary" size="sm" inline={false} wrap="prewrap">
        {reply.content}
      </Text>
      <ReplyActionGroup
        reply={reply}
        postId={reply.referenceId}
        onReply={onReply}
      />
      {reply.replies.length > 0 && (
        <ReplyGroup replies={reply.replies} root={reply} onReply={onReply} />
      )}
    </div>
  );
};

export default PostReply;
