import { useMutation } from "@tanstack/react-query";
import httpClient from "../../http/httpClient";
import { updateReplyBy } from "./utils";
import { UserReplyDto } from "../../types/Post";

const useLikeReply = (postId: number) => {
  const { mutate, isPending } = useMutation<unknown, unknown, UserReplyDto>({
    mutationFn: (reply) =>
      reply.liked
        ? httpClient.delete(`/reply/like/${reply.id}`)
        : httpClient.post(`/reply/like/${reply.id}`),
    onSuccess: (_, reply) => {
      // If reply.referenceId is postId, it means the reply is a root level reply, therefore not in group
      updateReplyBy(reply.id, postId, postId !== reply.referenceId, (reply) => {
        const originalLiked = reply.liked;
        reply.likeCount += originalLiked ? -1 : 1;
        reply.liked = !originalLiked;
      });
    },
  });

  return {
    likeReply: mutate,
    liking: isPending,
  };
};

export default useLikeReply;
