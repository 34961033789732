import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../query/queryKey";
import httpClient from "../http/httpClient";

const useImage = (type: "post", id: number, image: string) => {
  const { data, isLoading } = useQuery<Blob>({
    queryKey: [QueryKey.IMAGE, "post", id, image],
    queryFn: () => httpClient.getFile<Blob>(`/blob/${type}/${image}`),
    gcTime: 1200000,
    staleTime: 600000,
    retry: 0,
  });

  return {
    image: data ?? null,
    isLoading,
  };
};

export default useImage;
