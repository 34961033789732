import React, { useMemo } from "react";
import { Order, OrderStatus } from "../../types/Order";
import Text from "../../component/Text";
import Button from "../../component/Button";
import CreateEditOrderModal from "./CreateEditOrderModal";
import DeleteOrderModal from "./DeleteOrderModal";
import useOrderSettlement from "../../hook/order/useOrderSettlement";
import { getReleaseTimeRange } from "../../utils/dateUtils";
import LogoIcon from "../../assets/LogoIcon.png";
import useOrderImage from "../../hook/order/useOrderImage";
import Loading from "../../component/Loading";

interface OrderCardProps {
  order: Order;
}

const getStatus = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.PENDING:
      return "Unsettled";
    case OrderStatus.COMPLETED:
      return "Settled";
  }
};

const Detail = ({ field, value }: { field: string; value: string }) => {
  return (
    <div>
      <Text inline={false} size="xs" weight="bold">
        {field}
      </Text>
      <Text inline={false} type="secondary" size="xs">
        {value}
      </Text>
    </div>
  );
};

const OrderTitle = ({
  order,
  mobile,
  image,
}: {
  order: Order;
  mobile: boolean;
  image?: Blob | null;
}) => {
  return (
    <div
      className={`relative mobile:flex-col flex-row mobile:items-start items-center mobile:gap-[0px] gap-[10px] ${
        mobile ? "mobile:hidden flex" : "mobile:flex hidden"
      }`}
    >
      <Text type="primary" size="base" inline={false} weight="bold">
        {order.name}
      </Text>
      <div className="mobile:hidden block w-[1px] h-[15px] bg-secondary" />
      <div className="flex items-center gap-[5px]">
        <Text type="secondary" size="xs">
          {order.brand}
        </Text>
        <div className="mobile:block hidden w-[1px] h-full bg-secondary" />
        <Text type="secondary" size="xs">
          {`${order.deposit + order.balance} ${order.currency}`}
        </Text>
      </div>
      <ActionButtons order={order} image={image ?? null} mobile />
    </div>
  );
};

const ActionButtons = ({
  order,
  image,
  mobile,
}: {
  order: Order;
  image: Blob | null;
  mobile: boolean;
}) => {
  return (
    <div
      className={`actions absolute top-0 right-0 flex gap-[5px] ${
        mobile ? "mobile:hidden flex" : "mobile:flex hidden"
      }`}
    >
      <CreateEditOrderModal action="EDIT" order={order} image={image} />
      <DeleteOrderModal order={order} />
    </div>
  );
};

const OrderCard: React.FC<OrderCardProps> = ({ order }) => {
  const { settleOrder, isSettling, unsettleOrder, isUnsettling } =
    useOrderSettlement();
  const { image, isLoading: loadingImage } = useOrderImage(order.image);
  const imageUrl = useMemo(() => {
    return image ? URL.createObjectURL(image) : LogoIcon;
  }, [image]);
  return (
    <div className="order-card h-fit flex flex-col mobile:p-[15px] gap-[5px] p-[8px] bg-white mobile:rounded-[10px] rounded-[6px] shadow-card">
      <OrderTitle order={order} mobile image={image} />
      <div className="main flex gap-[10px]">
        <div className="flex flex-col sm:gap-[10px] gap-[5px]">
          <div className="xl:w-[175px] xl:h-[175px] lg:w-[160px] lg:h-[160px] md:w-[150px] md:h-[150px] sm:w-[175px] sm:h-[175px] mobile:w-[150px] mobile:h-[150px] w-[120px] h-[120px] flex justify-center items-center bg-whitegray rounded-[6px] overflow-hidden">
            {loadingImage ? (
              <Loading color="secondary" size="28px" />
            ) : (
              <img
                className={`${
                  image ? "w-full h-full" : "w-[60%] opacity-50"
                } object-cover`}
                alt="Image of ${order.name}"
                src={imageUrl}
              />
            )}
          </div>
          <OrderTitle order={order} mobile={false} />
        </div>
        <div className="relative flex flex-col grow justify-between">
          <ActionButtons order={order} image={image} mobile={false} />
          <div className="grid sm:grid-cols-1 grid-cols-2 gap-[5px]">
            <Detail
              field="Deposit"
              value={`${order.deposit.toFixed(2)} ${order.currency}`}
            />
            <Detail
              field="Balance"
              value={`${order.balance.toFixed(2)} ${order.currency}`}
            />
            <Detail
              field="Release"
              value={getReleaseTimeRange(
                order.releaseType,
                order.releaseFromDate,
                order.releaseToDate
              )}
            />
            <Detail field="Status" value={getStatus(order.status)} />
          </div>
          <Button
            className="self-end"
            type="outline"
            color={order.status === OrderStatus.PENDING ? "blue" : "red"}
            size="xs"
            loading={isSettling || isUnsettling}
            text={
              order.status === OrderStatus.PENDING
                ? "Settle Order"
                : "Unsettle Order"
            }
            onClick={() => {
              if (order.status === OrderStatus.PENDING) {
                settleOrder(order.id);
              } else {
                unsettleOrder(order.id);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
