import { useQuery } from "@tanstack/react-query";
import { Order } from "../../types/Order";
import { QueryKey } from "../../query/queryKey";
import useAuthenticatedQueryFn from "../useAuthenticatedQueryFn";
import httpClient from "../../http/httpClient";

const useOrders = () => {
  const { data, error, isLoading } = useQuery<ReadonlyArray<Order> | null>({
    queryKey: [QueryKey.ORDERS],
    queryFn: useAuthenticatedQueryFn(() => httpClient.get("/order/list")),
    gcTime: 3600000,
    staleTime: 1800000,
    retry: 0,
  });

  return {
    orders: data ?? [],
    isLoading,
    error,
  };
};

export default useOrders;
