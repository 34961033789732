import useAllPosts from "../../hook/post/useAllPosts";
import PostList from "./PostList";

const Following = () => {
  const { isLoading, posts, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useAllPosts(true);
  return (
    <PostList
      from="FOLLOWING"
      isLoading={isLoading}
      posts={posts}
      canLoadMore={hasNextPage}
      loadingMore={isFetchingNextPage}
      loadMore={fetchNextPage}
    />
  );
};

export default Following;
