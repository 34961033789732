import React from "react";
import Text from "./Text";

interface SwitchProps {
  title?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  style?: "light" | "dark";
}

const Switch: React.FC<SwitchProps> = ({
  title,
  value,
  onChange,
  style = "light",
}) => {
  return (
    <div>
      {title && (
        <div>
          <Text size="xs" type="secondary">
            {title}
          </Text>
        </div>
      )}
      <div
        onClick={() => onChange(!value)}
        className={`mobile:w-[75px] w-[60px] mobile:h-[40px] h-[32px] p-[3px] mobile:rounded-[20px] rounded-[16px] ${
          value
            ? "bg-green-500"
            : style === "light"
            ? "bg-whitegray"
            : "bg-gray-500"
        } cursor-pointer transition-colors duration-100`}
      >
        <div
          className={`relative mobile:w-[34px] w-[26px] mobile:h-[34px] h-[26px] mobile:rounded-[17px] rounded-[13px] bg-white ${
            value ? "mobile:left-[35px] left-[28px]" : "left-[0px]"
          } transition-position duration-100`}
        />
      </div>
    </div>
  );
};

export default Switch;
