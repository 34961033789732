import Text from "../../component/Text";
import { getFormatedDateTime } from "../../utils/dateUtils";
import { GoTrash } from "react-icons/go";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { BiLike, BiSolidLike } from "react-icons/bi";
import React from "react";
import { UserReplyDto } from "../../types/Post";
import useSelf from "../../hook/user/useSelf";
import Action from "./Action";
import useLikeReply from "../../hook/post/useLikeReply";
import DeleteReplyModal from "./DeleteReplyModal";

const ReplyActionGroup = ({
  reply,
  inGroup = false,
  postId,
  onReply,
}: {
  reply: UserReplyDto;
  postId: number;
  inGroup?: boolean;
  onReply: (reply: UserReplyDto) => void;
}) => {
  const { self } = useSelf();
  const { likeReply, liking } = useLikeReply(postId);
  return (
    <div className="w-full flex justify-end gap-[10px]">
      <Text type="secondary" size="xs">
        {getFormatedDateTime(reply.createdAt)}
      </Text>
      {self && self.id === reply.user.id && (
        <DeleteReplyModal
          reply={reply}
          postId={postId}
          trigger={<Action authenticated icon={<GoTrash />} />}
        />
      )}
      <Action
        authenticated={!!self}
        icon={<IoChatboxEllipsesOutline />}
        text={inGroup ? undefined : reply.replyCount.toString()}
        onClick={() => onReply(reply)}
      />
      <Action
        authenticated={!!self}
        loading={liking}
        icon={reply.liked ? <BiSolidLike /> : <BiLike />}
        onClick={() => likeReply(reply)}
        text={reply.likeCount.toString()}
      />
    </div>
  );
};

export default ReplyActionGroup;
