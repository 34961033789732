import { useQuery } from "@tanstack/react-query";
import { SelfDto } from "../../types/User";
import { QueryKey } from "../../query/queryKey";
import httpClient from "../../http/httpClient";

const useUnfollowedUsers = () => {
  const { data, isLoading } = useQuery<Readonly<Array<SelfDto>> | null>({
    queryKey: [QueryKey.UNFOLLOWED_USERS],
    queryFn: () => httpClient.get(`/user/list/unfollowed`),
    gcTime: 3600000,
    staleTime: 1800000,
    retry: 0,
  });

  return {
    users: data ?? [],
    isLoading,
  };
};

export default useUnfollowedUsers;
