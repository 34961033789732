import React from "react";
import UserProfile from "./UserProfile";
import { useParams } from "react-router-dom";
import useUserProfile from "../../hook/user/useUserProfile";
import Loading from "../../component/Loading";
import Text from "../../component/Text";

interface ProfileProps {
  readonly: boolean;
}

const Profile: React.FC<ProfileProps> = () => {
  const { username } = useParams();
  const { userProfile, isLoading } = useUserProfile(username ?? "");

  if (isLoading) {
    return (
      <div className="w-full h-[300px] flex justify-center gap-[10px] items-center">
        <Loading color="secondary" />
        <Text type="secondary" size="sm">
          Loading user profile...
        </Text>
      </div>
    );
  }
  return userProfile ? (
    <UserProfile profile={userProfile} readonly />
  ) : (
    <div className="w-full h-[300px] flex justify-center items-center">
      <Text type="secondary">Failed to load user profile.</Text>
    </div>
  );
};

export default Profile;
