import { useQuery } from "@tanstack/react-query";
import { UserProfileDto } from "../../types/User";
import { QueryKey } from "../../query/queryKey";
import httpClient from "../../http/httpClient";

const useUserProfile = (username: string | null) => {
  const { data, isLoading, error } = useQuery<Readonly<UserProfileDto> | null>({
    queryKey: [QueryKey.USER, username],
    queryFn: () =>
      username ? httpClient.get(`/user/username/${username}`) : null,
    gcTime: 3600000,
    staleTime: 1800000,
    retry: 0,
  });

  return {
    userProfile: data,
    isLoading,
  };
};

export default useUserProfile;
