import { QueryKey } from "../../query/queryKey";
import httpClient from "../../http/httpClient";
import { PostDto } from "../../types/Post";
import { useInfiniteQuery } from "@tanstack/react-query";

const useAllPosts = (onlyFollow: boolean) => {
  const {
    data,
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<ReadonlyArray<PostDto>>({
    queryKey: [onlyFollow ? QueryKey.POSTS_ALL_FOLLOWING : QueryKey.POSTS_ALL],
    queryFn: ({ pageParam }) =>
      httpClient.get(
        `/post/${onlyFollow ? "following" : "all"}?offset=${pageParam}`
      ),
    initialPageParam: "",
    getNextPageParam: (lastPage) =>
      lastPage.length == 10 ? lastPage[lastPage.length - 1].id : null,
    gcTime: 1200000,
    staleTime: 600000,
    retry: 0,
  });

  return {
    posts: data ? data.pages.flatMap((page) => page) : [],
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetching,
  };
};

export default useAllPosts;
