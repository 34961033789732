import { useMutation } from "@tanstack/react-query";
import { Order, OrderReleaseType } from "../../types/Order";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import { Currency } from "../../types/shared";
import { useNotification } from "react-easy-notification";

interface AmendOrderRequest {
  id: number;
  name: string;
  brand: string;
  currency: Currency;
  deposit: number;
  balance: number;
  image: string | null; // Kept as reference to the old image so it can be invalidated from cache
  releaseType: OrderReleaseType;
  releaseFromDate: Date;
  releaseToDate: Date | null;
  comment: string | null;
}

const useAmendOrder = () => {
  const { pushNotification } = useNotification();
  const { mutate, isPending, error } = useMutation<
    Readonly<Order>,
    unknown,
    [AmendOrderRequest, File | null]
  >({
    mutationFn: (order) =>
      httpClient.post<AmendOrderRequest, Readonly<Order>>(
        "/order/amend",
        order[0],
        order[1] ?? new File([], "")
      ),
    onSuccess: (order, request) => {
      queryClient
        .invalidateQueries({ queryKey: [QueryKey.ORDERS] })
        .then(() => {
          queryClient.removeQueries({
            queryKey: [QueryKey.ORDER_IMAGE, request[0].image],
          });
          pushNotification({
            text: "Successfully edited the order.",
            type: "success",
          });
        });
    },
    onError: () => {
      pushNotification({
        text: "Failed to edit the order.",
        type: "danger",
      });
    },
  });

  return {
    amendOrder: mutate,
    error,
    isLoading: isPending,
  };
};

export default useAmendOrder;
