import React, { ReactNode, useRef, useState, useEffect } from "react";
import { Modal as MuiModal } from "@mui/material";

interface PureModalProps {
  open?: boolean;
  onOpen?: (open: boolean) => void;
  onClose?: () => void;
  trigger: ReactNode;
  children: ReactNode;
  disableClickOutside?: boolean;
}

const PureModal: React.FC<PureModalProps> = ({
  open,
  onOpen,
  onClose,
  trigger,
  children,
  disableClickOutside,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const openModal = () => {
    if (open !== undefined && onOpen !== undefined) {
      onOpen(true);
    } else {
      setIsOpen(true);
    }
  };

  const closeModal = () => {
    if (open !== undefined && onClose !== undefined) {
      onClose();
    } else {
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event: Event) => {
    if (
      !disableClickOutside &&
      ref.current &&
      !ref.current.contains(event.target as Node)
    ) {
      closeModal();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      if (!open) {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, disableClickOutside]); // include disableClickOutside to update the handleClickOutside function

  return (
    <div>
      <div onClick={openModal}>{trigger}</div>
      <MuiModal open={open ?? isOpen}>
        <div className="w-full h-full flex justify-center items-center">
          <div ref={ref}>{children}</div>
        </div>
      </MuiModal>
    </div>
  );
};

export default PureModal;
