import { QuarterValue } from "../constant/date";
import { OrderReleaseType } from "../types/Order";
import { DateTime } from "luxon";

export const getQuarter = (date: Date): QuarterValue => {
  if ([0, 1, 2].includes(date.getMonth())) return 1;
  if ([3, 4, 5].includes(date.getMonth())) return 2;
  if ([6, 7, 8].includes(date.getMonth())) return 3;
  if ([9, 10, 11].includes(date.getMonth())) return 4;
  return 1;
};

export const getMonthByQuarter = (quarter: QuarterValue) => {
  if (quarter === 1) return 0;
  if (quarter === 2) return 3;
  if (quarter === 3) return 6;
  if (quarter === 4) return 9;
  return 0;
};

export const getDateUTC = (
  year: number,
  month: number,
  date: number,
  hour: number,
  minute: number,
  seconds: number,
  ms: number
): Date => {
  return new Date(Date.UTC(year, month, date, hour, minute, seconds, ms));
};

export const getReleaseTime = (
  releaseType: OrderReleaseType,
  releaseTime: string
) => {
  const releaseDateTime = DateTime.fromISO(releaseTime);
  switch (releaseType) {
    case OrderReleaseType.YEAR:
      return releaseDateTime.toFormat("yyyy");
    case OrderReleaseType.MONTH:
      return releaseDateTime.toFormat("yyyy LLL");
    case OrderReleaseType.DATE:
      return releaseDateTime.toFormat("yyyy LLL dd");
    case OrderReleaseType.QUARTER:
      return `${releaseDateTime.toFormat("yyyy")} Q${getQuarter(
        releaseDateTime.toJSDate()
      )}`;
  }
};

export const getReleaseTimeRange = (
  releaseType: OrderReleaseType,
  releaseFromDate: string,
  releaseToDate: string | null
) => {
  return `${getReleaseTime(releaseType, releaseFromDate)}${
    !!releaseToDate ? ` - ${getReleaseTime(releaseType, releaseToDate)}` : ""
  }`;
};

export const getFormatedDateTime = (date: string) => {
  const dateTime = DateTime.fromISO(date);
  return dateTime.toFormat("yyyy LLL dd HH:mm");
};
