import { UserDto, UserProfileDto } from "../../types/User";
import useProfileUpdate from "../../hook/profile/useProfileUpdate";
import useAvatarUpload from "../../hook/profile/useAvatarUpload";
import React, { useEffect, useRef, useState } from "react";
import MainBox from "../../component/MainBox";
import { compressFile, CompressLevel } from "../../utils/fileUtils";
import Text from "../../component/Text";
import Input from "../../component/Input";
import Button from "../../component/Button";
import useCollectibles from "../../hook/collectible/useCollectibles";
import ExpandableCollectibleCard from "../collectible/ExpandableCollectibleCard";
import useSelf from "../../hook/user/useSelf";
import { useNotification } from "react-easy-notification";
import useUserFollow from "../../hook/user/useUserFollow";
import FollowModal from "./FollowModal";
import useSignOut from "../../hook/auth/useSignOut";
import { useNavigate } from "react-router-dom";
import Avatar from "../../component/Avatar";
import LoadingWithText from "../../component/LoadingWithText";

interface UserProfileProps {
  profile: UserProfileDto;
  readonly: boolean;
}

const ShareButton = ({ user }: { user: UserDto }) => {
  const { pushNotification } = useNotification();
  const [sharing, setSharing] = useState(false);
  return (
    <Button
      fullWidth
      loading={sharing}
      type="outline"
      text="Share Profile"
      onClick={() => {
        setSharing(true);
        navigator.clipboard
          .writeText(
            `${window.location.hostname}${
              window.location.port ? `:${window.location.port}` : ""
            }/profile/${user.username}`
          )
          .then(() => {
            pushNotification({
              text: "Copied the profile URL into your clipboard",
              type: "success",
            });
            setSharing(false);
          });
      }}
    />
  );
};

const UserProfile: React.FC<UserProfileProps> = ({ profile, readonly }) => {
  const { self } = useSelf();
  const { signout } = useSignOut();
  const navigate = useNavigate();
  const { user } = profile;
  const isSelf = self?.id === user.id;
  const { collectibles, isLoading: loadingCollectibles } = useCollectibles(
    isSelf && !readonly ? undefined : user.id
  );
  const { updateProfile, isLoading: updatingProfile } = useProfileUpdate();
  const { uploadAvatar, isLoading: uploadingAvatar } = useAvatarUpload();
  const { pushNotification } = useNotification();
  const { followUser, isLoading: following } = useUserFollow(true);
  const { followUser: unfollowUser, isLoading: unfollowing } =
    useUserFollow(false);
  const [editingProfile, setEditingProfile] = useState(false);
  const [nickname, setNickname] = useState(user?.nickname ?? "");
  const [biography, setBiography] = useState(user?.biography ?? "");

  const avatarRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (user) {
      setNickname(user.nickname ?? "");
      setBiography(user.biography ?? "");
    }
  }, [user]);

  const profileUpdated =
    nickname !== user.nickname || biography !== user.biography;

  const submitProfileUpdate = () => {
    if (user && profileUpdated) {
      updateProfile(
        {
          id: user.id,
          nickname,
          biography,
        },
        {
          onSuccess: () => {
            setEditingProfile(false);
            pushNotification({
              text: "Successfully updated the profile.",
              type: "success",
            });
          },
          onError: () => {
            pushNotification({
              text: "Failed to update the profile.",
              type: "danger",
            });
          },
        }
      );
    }
  };

  const profileButtons = () => {
    if (!readonly) {
      return (
        <>
          {!editingProfile && (
            <>
              <Button
                fullWidth
                type="outline"
                text="Edit Profile"
                onClick={() => {
                  setEditingProfile(true);
                }}
              />
              <ShareButton user={user} />
            </>
          )}
          {editingProfile && (
            <>
              <Button
                fullWidth
                loading={updatingProfile}
                text="Save"
                disabled={!profileUpdated}
                onClick={submitProfileUpdate}
              />
              <Button
                fullWidth
                loading={updatingProfile}
                type="outline"
                color="red"
                text="Cancel"
                onClick={() => {
                  setEditingProfile(false);
                }}
              />
            </>
          )}
          {!editingProfile && (
            <>
              <Button
                fullWidth
                type="outline"
                color="red"
                text="Change Username"
                onClick={() => {}}
              />
              <Button
                fullWidth
                type="outline"
                color="red"
                text="Sign Out"
                onClick={() => {
                  signout();
                  navigate("/");
                }}
              />
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {self &&
            !isSelf &&
            (user.followed ? (
              <Button
                fullWidth
                color="red"
                text="Unfollow"
                loading={unfollowing}
                onClick={() => unfollowUser(user.username)}
              />
            ) : (
              <Button
                fullWidth
                color="blue"
                text="Follow"
                loading={following}
                onClick={() => followUser(user.username)}
              />
            ))}
          <ShareButton user={user} />
        </>
      );
    }
  };

  return (
    <MainBox className="py-[10px] flex sm:flex-row flex-col h-auto gap-[10px]">
      <div className="profile sm:w-[300px] min-w-[300px] w-full p-[15px] rounded-[6px] bg-white flex flex-col items-center gap-[10px]">
        <div
          className={`sm:w-[150px] sm:h-[150px] w-[120px] h-[120px] sm:rounded-[75px] rounded-[60px] bg-whitegray ${
            readonly ? "" : "cursor-pointer"
          } overflow-hidden`}
          onClick={() => {
            if (!readonly && !uploadingAvatar) {
              avatarRef.current?.click();
            }
          }}
        >
          <Avatar className="sm:block hidden" avatar={user.avatar} size={150} />
          <Avatar className="sm:hidden block" avatar={user.avatar} size={120} />
          <input
            ref={avatarRef}
            type="file"
            accept="image/png, image/jpeg"
            onChange={(event) => {
              const file = event.target.files?.[0];
              if (user && file) {
                compressFile(CompressLevel.HIGH, file, (result) => {
                  uploadAvatar([
                    {
                      id: user.id,
                      avatar: user.avatar,
                    },
                    result,
                  ]);
                });
              }
            }}
            hidden
          />
        </div>
        <div className="username w-full flex flex-col items-center">
          {!editingProfile && (
            <Text
              type={user?.nickname ? "primary" : "secondary"}
              inline={false}
            >
              {user?.nickname ?? "Nick Name"}
            </Text>
          )}
          {editingProfile && (
            <Input
              type="text"
              inputClassName="w-full"
              value={nickname}
              onChange={setNickname}
              title="Nick Name"
            />
          )}
          <Text type="secondary" size="sm" inline={false}>
            {user?.username}
          </Text>
        </div>
        <div className="follow flex gap-[10px] justify-center">
          <FollowModal
            user={user}
            count={profile.followerCount}
            type="FOLLOWER"
          />
          <FollowModal
            user={user}
            count={profile.followingCount}
            type="FOLLOWING"
          />
        </div>
        <div className="bio w-full">
          {!editingProfile && (
            <Text
              type={user?.biography ? "primary" : "secondary"}
              inline={false}
              wrap="prewrap"
              size="sm"
            >
              {user?.biography ?? "Please add your biography here."}
            </Text>
          )}
          {editingProfile && (
            <Input
              inputClassName="h-[100px]"
              type="textarea"
              value={biography}
              onChange={setBiography}
              title="Biography"
            />
          )}
        </div>
        {profileButtons()}
      </div>
      <div className="dashboard grow p-[15px] rounded-[6px] bg-white">
        <div className="w-full flex flex-col gap-[10px]">
          <Text size="sm">Collection</Text>
          {loadingCollectibles && (
            <LoadingWithText text="Loading collectibles..." />
          )}
          {!loadingCollectibles && !collectibles.length && (
            <div className="w-full sm:h-[300px] h-[150px] flex justify-center items-center">
              <Text type="secondary" size="sm">
                This collector has empty collection.
              </Text>
            </div>
          )}
          {!loadingCollectibles && !!collectibles.length && (
            <div className="collectibles grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-[10px]">
              {collectibles.map((collectible) => {
                return (
                  <ExpandableCollectibleCard
                    user={user}
                    readonly={readonly}
                    key={collectible.id}
                    collectible={collectible}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </MainBox>
  );
};

export default UserProfile;
