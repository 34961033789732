import { useMutation } from "@tanstack/react-query";
import httpClient from "../../http/httpClient";
import { updatePostBy } from "./utils";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import { useNotification } from "react-easy-notification";

interface ReplyPostRequest {
  id: number;
  content: string;
}

const useReplyPost = () => {
  const { pushNotification } = useNotification();
  const { mutate, isPending } = useMutation<unknown, unknown, ReplyPostRequest>(
    {
      mutationFn: (request) => httpClient.post("/reply/post", request),
      onSuccess: (_, request) => {
        updatePostBy(request.id, (post) => {
          post.replyCount += 1;
        });
        queryClient
          .invalidateQueries({
            queryKey: [QueryKey.POST_REPLIES, request.id],
          })
          .then(() => {
            pushNotification({
              type: "success",
              text: "Successfully post the reply.",
            });
          });
      },
      onError: () => {
        pushNotification({
          type: "danger",
          text: "Failed to post the reply.",
        });
      },
    }
  );

  return {
    replyPost: mutate,
    replying: isPending,
  };
};

export default useReplyPost;
