import { Collectible } from "../../types/Collectible";
import React, { MouseEventHandler, useMemo, useRef, useState } from "react";
import Text from "../../component/Text";
import PureModal from "../../component/PureModal";
import { getReleaseTime } from "../../utils/dateUtils";
import { OrderReleaseType } from "../../types/Order";
import LogoIcon from "../../assets/LogoIcon.png";
import { AiOutlineClose } from "react-icons/ai";
import CreateEditCollectibleModal from "./CreateEditCollectibleModal";
import DeleteCollectibleModal from "./DeleteCollectibleModal";
import useCollectibleImage from "../../hook/collectible/useCollectibleImage";
import Loading from "../../component/Loading";
import { UserDto } from "../../types/User";
import { IoEyeOffOutline } from "react-icons/io5";

interface ExpandableCollectibleCardProps {
  user?: UserDto;
  readonly?: boolean;
  collectible: Collectible;
}

const Image = ({ name, src }: { name: string; src: string | null }) => {
  return (
    <img
      alt={`Image of ${name}`}
      className={`${src ? "w-full h-full" : "w-[70%] opacity-60"} object-cover`}
      src={src ?? LogoIcon}
    />
  );
};

const CollectibleCard = ({ collectible }: { collectible: Collectible }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [rotateY, setRotateY] = useState(0);
  const [rotateX, setRotateX] = useState(0);
  const [scale, setScale] = useState(false);
  const rotateCard: MouseEventHandler = (e) => {
    if (ref.current) {
      const bounds = ref.current.getBoundingClientRect();
      const centerX = bounds.x + bounds.width / 2;
      const centerY = bounds.y + bounds.height / 2;
      setRotateY(-((centerX - e.pageX) / (bounds.width / 2)) * 20);
      setRotateX(((centerY - e.pageY) / (bounds.height / 2)) * 20);
    }
  };
  const { image, isLoading: loadingImage } = useCollectibleImage(
    collectible.image
  );

  const imageUrl = useMemo(() => {
    return image ? URL.createObjectURL(image) : null;
  }, [image]);

  return (
    <div
      onMouseMove={rotateCard}
      onMouseLeave={() => {
        setRotateY(0);
        setRotateX(0);
        setScale(false);
      }}
      onMouseEnter={() => setScale(true)}
      ref={ref}
      className="relative aspect-3/4 rounded-[10px] overflow-hidden flex items-end bg-secondary cursor-pointer hover:scale-110"
      style={{
        transition: "transform 0.05s",
        transformOrigin: "center",
        transform: `rotateY(${rotateY}deg) rotateX(${rotateX}deg) ${
          scale ? "scale(1.2)" : ""
        }`,
        zIndex: scale ? 1 : 0,
        boxShadow: scale ? "0 0 20px 0 rgb(0, 0, 0, 0.5)" : "",
      }}
    >
      <div className="absolute w-full h-full flex justify-center items-center">
        {loadingImage ? (
          <Loading color="white" size="28px" />
        ) : (
          <Image name={collectible.name} src={imageUrl} />
        )}
      </div>
      <div className="absolute w-full h-full bg-gradient-to-t from-black to-transparent" />
      <div className="relative w-full p-[10px] z-1">
        <div className="flex justify-between items-center">
          <Text
            className="overflow-hidden whitespace-nowrap overflow-ellipsis"
            type="white"
          >
            {collectible.name}
          </Text>
          {!collectible.public && (
            <IoEyeOffOutline className="flex-none text-white opacity-80 ml-[5px]" />
          )}
        </div>
        <div className="flex items-center gap-[5px]">
          <Text
            className="overflow-hidden flex-none max-w-[60px] whitespace-nowrap text-ellipsis"
            type="white"
            size="xs"
          >
            {collectible.brand}
          </Text>
          <Text type="white" size="xs">
            |
          </Text>
          <Text
            className="overflow-hidden whitespace-nowrap text-ellipsis"
            type="white"
            size="xs"
          >
            {collectible.price.toFixed(2)} {collectible.currency}
          </Text>
        </div>
      </div>
    </div>
  );
};

const CollectibleCardDetails = ({
  collectible,
  onClose,
  setEditing,
  readonly,
  user,
}: {
  collectible: Collectible;
  onClose: () => void;
  setEditing: (editing: boolean) => void;
  readonly: boolean;
  user?: UserDto;
}) => {
  const { image } = useCollectibleImage(collectible.image);

  const imageUrl = useMemo(() => {
    return image ? URL.createObjectURL(image) : null;
  }, [image]);
  return (
    <div className="relative sm:w-fit max-w-[100vw] w-[360px] max-h-screen supports-max-h-dvh:max-h-dvh p-[10px] flex sm:flex-row flex-col sm:items-end gap-[10px]">
      <div className="md:w-[430px] sm:w-[400px] w-full aspect-3/4 rounded-[10px] overflow-hidden flex justify-center items-center bg-whitegray">
        <Image name={collectible.name} src={imageUrl} />
      </div>
      <div className="sm:absolute relative sm:right-[10px] sm:top-[10px] flex justify-end items-center gap-[5px]">
        {!readonly && (
          <>
            <CreateEditCollectibleModal
              action="EDIT"
              onOpen={() => {
                setEditing(true);
              }}
              onClose={() => {
                setEditing(false);
              }}
              collectible={collectible}
              image={image}
            />
            <DeleteCollectibleModal
              collectible={collectible}
              onOpen={() => {
                setEditing(true);
              }}
              onClose={() => {
                setEditing(false);
              }}
            />
          </>
        )}
        <Text type="white" pointer onClick={onClose}>
          <AiOutlineClose className="mobile:text-[18px] text-[22px]" />
        </Text>
      </div>
      <div className="relative flex flex-col gap-[5px]">
        <Text type="white" size="lg" weight="bold" inline={false}>
          {collectible.name}
        </Text>
        <Text type="white">Presented by {collectible.brand}</Text>
        <Text type="white">
          Added to {user ? `${user.nickname ?? user.username}'s` : "your"}{" "}
          collection on{" "}
          {getReleaseTime(OrderReleaseType.DATE, collectible.addedAt)}
        </Text>
        <Text type="white">
          At {collectible.price.toFixed(2)} {collectible.currency}
        </Text>
      </div>
    </div>
  );
};

const ExpandableCollectibleCard: React.FC<ExpandableCollectibleCardProps> = ({
  collectible,
  user,
  readonly = false,
}) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [editing, setEditing] = useState(false);
  return (
    <PureModal
      open={openDetails}
      onOpen={() => setOpenDetails(true)}
      onClose={() => setOpenDetails(false)}
      trigger={<CollectibleCard collectible={collectible} />}
      disableClickOutside={editing}
    >
      <CollectibleCardDetails
        user={user}
        readonly={readonly}
        collectible={collectible}
        onClose={() => setOpenDetails(false)}
        setEditing={(editing: boolean) => setEditing(editing)}
      />
    </PureModal>
  );
};

export default ExpandableCollectibleCard;
