import { StorageKey } from "../constant/localStorage";

const BASE_URL = process.env.REACT_APP_API_URL ?? "https://localhost:8443";

class HttpError extends Error {
  readonly status: number;

  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }
}

const getFormData = <P>(file: File | File[], payload: P) => {
  const formData = new FormData();
  if (file instanceof File) {
    formData.append("file", file);
  } else {
    if (file.length) {
      file.forEach((f) => {
        formData.append("file", f);
      });
    } else {
      formData.append("file", new File([], ""));
    }
  }
  formData.append(
    "payload",
    new Blob([JSON.stringify(payload)], { type: "application/json" })
  );
  return formData;
};

const request = async <P, R>({
  method,
  url,
  headers = {},
  payload,
  file,
  isFile = false,
}: {
  method: string;
  url: string;
  headers?: Record<string, string>;
  isFile?: boolean;
  payload?: P;
  file?: File | File[];
}): Promise<R> => {
  const accessToken = localStorage.getItem(StorageKey.ACCESS_TOKEN);
  const isAuth = url.includes("/api/auth");
  if (accessToken && !isAuth) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }
  let ok = false;
  let status: number = 500;
  if (!file && !isFile) {
    headers["Content-Type"] = "application/json";
  }
  return fetch(`${BASE_URL}${url}`, {
    mode: "cors",
    method,
    headers: {
      ...headers,
    },
    body: file
      ? getFormData(file, payload)
      : payload
      ? JSON.stringify(payload)
      : undefined,
  })
    .then((res) => {
      ok = res.ok;
      status = res.status;
      return isFile ? res.blob() : res.json();
    })
    .then((json) => {
      if (ok) {
        return json;
      } else {
        throw new HttpError(status, json.message);
      }
    })
    .catch((err: Error) => {
      throw new HttpError(status, err.message);
    });
};

const get = async <R>(url: string): Promise<R> =>
  request({
    method: "GET",
    url,
  });

const getFile = async <R>(url: string): Promise<R> =>
  request({
    method: "GET",
    url,
    isFile: true,
  });

const post = async <P, R>(
  url: string,
  payload?: P,
  file?: File | File[]
): Promise<R> =>
  request({
    method: "POST",
    url,
    payload,
    file,
  });

const deleteFn = async <R>(url: string): Promise<R> =>
  request({ method: "DELETE", url });

const httpClient = {
  get,
  getFile,
  post,
  delete: deleteFn,
};

export default httpClient;
