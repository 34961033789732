export enum Currency {
  HKD = "HKD",
  CNY = "CNY",
  USD = "USD",
  JPY = "JPY",
  EUR = "EUR",
  GBP = "GBP",
  AUD = "AUD",
}

export enum ReferenceType {
  POST = "POST",
  REPLY = "REPLY",
  USER = "USER",
}
