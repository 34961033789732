import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Home from "../page/Home";
import News from "../page/News";
import Orders from "../page/Orders";
import Collection from "../page/collectible/Collection";
import SignIn from "../page/SignIn";
import SignUp from "../page/SignUp";
import AuthProvider from "./AuthProvider";
import useSelf from "../hook/user/useSelf";
import Profile from "../page/profile/Profile";
import SelfProfile from "../page/profile/SelfProfile";
import Community from "../page/community/Community";
import Following from "../page/community/Following";
import Latest from "../page/community/Latest";
import PostDetails from "../page/community/PostDetails";

const Authenticated = ({ children }: { children: JSX.Element }) => {
  const { self, isLoading } = useSelf();
  if (!isLoading && !self) {
    return <Navigate to="/signin" />;
  } else return children;
};

const Root = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="news" element={<News />} />
            <Route path="community" element={<Community />}>
              <Route path="latest" element={<Latest />} />
              <Route path="post/:postId" element={<PostDetails />} />
              <Route
                path="following"
                element={
                  <Authenticated>
                    <Following />
                  </Authenticated>
                }
              />
              <Route index element={<Navigate to="latest" replace />} />
              <Route path="*" element={<Navigate to="latest" replace />} />
            </Route>
            <Route
              path="orders"
              element={
                <Authenticated>
                  <Orders />
                </Authenticated>
              }
            />
            <Route
              path="collection"
              element={
                <Authenticated>
                  <Collection />
                </Authenticated>
              }
            />
            <Route path="signin" element={<SignIn />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="profile">
              <Route
                index
                element={
                  <Authenticated>
                    <SelfProfile />
                  </Authenticated>
                }
              />
              <Route path=":username" element={<Profile readonly />} />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Root;
