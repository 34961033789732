import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../../query/queryKey";
import { PostDto } from "../../types/Post";
import httpClient from "../../http/httpClient";

const usePostImages = (post: PostDto | null, limit: number) => {
  const { data, isLoading, isFetching } = useQuery<Array<Blob>>({
    queryKey: [QueryKey.POST_IMAGES, post?.id ?? null],
    queryFn: () => {
      return Promise.all(
        post?.images
          .slice(0, limit)
          .map((image) => httpClient.getFile<Blob>(`/blob/post/${image}`)) ?? []
      );
    },
    gcTime: 1200000,
    staleTime: 600000,
    retry: 0,
    enabled: post !== null,
  });

  return {
    images: data ?? [],
    isLoading,
    isFetching,
  };
};

export default usePostImages;
