import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import useAccessToken from "./useAccessToken";

interface SignUpRequest {
  email: string;
  username: string;
  password: string;
}

interface SignUpResponse {
  email: string;
  username: string;
  accessToken: string;
}

const useSignUp = () => {
  const { persistJwt } = useAccessToken();
  const signup = async (request: SignUpRequest) => {
    return httpClient
      .post<SignUpRequest, SignUpResponse>("/api/auth/signup", request)
      .then((res) => {
        queryClient.setQueryData([QueryKey.USER_SELF], res);
        persistJwt(res.accessToken);
      });
  };

  return {
    signup,
  };
};

export default useSignUp;
