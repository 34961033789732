import { useRecoilState } from "recoil";
import {
  newNotificationCountState,
  showNotificationsState,
} from "../state/notificationState";
import Text from "../component/Text";
import useNotifications from "../hook/useNotifications";
import React, { useEffect, useRef } from "react";
import LoadingWithText from "../component/LoadingWithText";
import Notification from "../component/Notification";
import { IoIosArrowForward } from "react-icons/io";
import InfiniteScroll from "react-infinite-scroll-component";

const Notifications = () => {
  const ref = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showNotifications, setShowNotifications] = useRecoilState(
    showNotificationsState
  );
  const [_newNotificationCount, setNewNotificationCount] = useRecoilState(
    newNotificationCountState
  );
  const {
    notifications,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    readAllNotifications,
    isLoading,
  } = useNotifications(showNotifications);

  const closeNotifications = () => {
    setShowNotifications(!showNotifications);
    // Mark notifications as read
    setNewNotificationCount(0);
    readAllNotifications();
  };

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      closeNotifications();
    }
  };

  useEffect(() => {
    // InfiniteScroll does not load when the initial list is shorter than the container.
    // Therefore, additional logics is required to load more data until the container is scrollable.
    if (showNotifications && hasNextPage) {
      const isScrollable =
        (scrollRef.current?.clientHeight ?? 0) <
        (scrollRef.current?.scrollHeight ?? 0);
      if (!isScrollable) {
        fetchNextPage();
      }
    }
  }, [scrollRef, showNotifications, hasNextPage]);

  useEffect(() => {
    if (showNotifications) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      if (!showNotifications) {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showNotifications, handleClickOutside]);

  const emptyMessage = () => {
    if (notifications.length === 0) {
      return (
        <div className="w-full sm:h-[300px] h-[150px] flex justify-center items-center">
          <Text type="secondary" size="sm">
            There is no notification yet.
          </Text>
        </div>
      );
    } else {
      return (
        <div className="w-full h-[100px] flex justify-center items-center">
          <Text type="secondary" size="sm">
            There is no more notification.
          </Text>
        </div>
      );
    }
  };

  return (
    <div
      className="absolute z-[10] w-[420px] p-[10px] max-w-full h-full transition-right duration-300"
      style={{
        right: showNotifications ? "0px" : "-420px",
      }}
      ref={ref}
    >
      <div className="flex flex-col h-full bg-white rounded-[6px] shadow-popup p-[10px]">
        <div className="flex justify-between items-center w-full border-b-[1px] border-whitegray pb-[15px]">
          <Text>Notifications</Text>
          <div
            className="flex items-center gap-[0px] cursor-pointer"
            onClick={closeNotifications}
          >
            <Text type="secondary" size="sm">
              Hide
            </Text>
            <IoIosArrowForward className="text-secondary" size="18px" />
          </div>
        </div>
        <div
          ref={scrollRef}
          className="w-full max-h-full overflow-auto"
          id="notification-list"
        >
          <InfiniteScroll
            className="w-full flex flex-col items-center gap-[10px] py-[10px]"
            dataLength={notifications.length}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={<LoadingWithText text="Loading notifications..." />}
            endMessage={emptyMessage()}
            scrollableTarget="notification-list"
            scrollThreshold={0.9}
          >
            {notifications.map((notification) => (
              <Notification key={notification.id} notification={notification} />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
