import { useQuery } from "@tanstack/react-query";
import { UserDto } from "../../types/User";
import { QueryKey } from "../../query/queryKey";
import httpClient from "../../http/httpClient";

// rendered is a trick to not load data when the modal is not yet open
const useFollows = (
  username: string,
  type: "FOLLOWING" | "FOLLOWER",
  rendered?: boolean
): {
  follows: Readonly<Array<UserDto>>;
  isLoading: boolean;
} => {
  const isFollowing = type === "FOLLOWING";
  const { data, isLoading } = useQuery<Readonly<Array<UserDto>> | null>({
    queryKey: [
      isFollowing ? QueryKey.FOLLOWINGS : QueryKey.FOLLOWERS,
      username,
    ],
    enabled: rendered,
    queryFn: () =>
      httpClient.get(
        isFollowing
          ? `/user/list/followings/${username}`
          : `/user/list/followers/${username}`
      ),
    gcTime: 3600000,
    staleTime: 1800000,
    retry: 0,
  });

  return {
    follows: data ?? [],
    isLoading,
  };
};

export default useFollows;
