import { PostDto } from "../../types/Post";
import React, { ReactNode, useState } from "react";
import Modal from "../../component/Modal";
import Text from "../../component/Text";
import Button from "../../component/Button";
import useDeletePost from "../../hook/post/useDeletePost";
import { useNavigate } from "react-router-dom";

interface DeletePostModalProps {
  post: PostDto;
  trigger: ReactNode;
}

const DeletePostModal: React.FC<DeletePostModalProps> = ({ post, trigger }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const { deletePost, deleting } = useDeletePost();
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={trigger}
      title="Delete Post"
      subtitle={post.title}
      showClose={false}
    >
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-col items-center gap-[3px]">
          <Text inline={false} size="sm">
            Are you sure you want to delete this post?
          </Text>
          <Text inline={false} size="sm">
            This cannot be undo.
          </Text>
        </div>
        {error && (
          <div className="w-full flex justify-center">
            <Text type="danger" size="xs">
              {error}
            </Text>
          </div>
        )}
        <div className="w-full flex justify-center gap-[10px]">
          <Button
            type="outline"
            color="red"
            text="Yes, I do"
            loading={deleting}
            onClick={() => {
              deletePost(post.id, {
                onSuccess: () => {
                  setOpen(false);
                  navigate("/community");
                },
                onError: (error) => {
                  if (error instanceof Error) {
                    setError(error.message);
                  } else {
                    setError("Failed to delete the post.");
                  }
                },
              });
            }}
          />
          <Button
            type="outline"
            color="yellow"
            text="No, I do not"
            disabled={deleting}
            onClick={() => {
              setOpen(false);
              setError("");
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeletePostModal;
