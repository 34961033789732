import { useMutation } from "@tanstack/react-query";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import { useNotification } from "react-easy-notification";

const useDeleteOrder = () => {
  const { pushNotification } = useNotification();
  const { mutate, isPending, error } = useMutation({
    mutationFn: (orderId: number) => httpClient.delete(`/order/${orderId}`),
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QueryKey.ORDERS] })
        .then(() => {
          pushNotification({
            text: "Successfully deleted the order.",
            type: "success",
          });
        });
    },
    onError: () => {
      pushNotification({
        text: "Failed to delete the order.",
        type: "danger",
      });
    },
  });
  return {
    deleteOrder: mutate,
    isLoading: isPending,
    error,
  };
};

export default useDeleteOrder;
