import { UserDto } from "../types/User";
import Avatar from "./Avatar";
import Text from "./Text";
import React from "react";
import { Link } from "react-router-dom";

interface AvatarUsernameProps {
  user: UserDto;
  size?: "sm" | "xs";
}

const AvatarUsername: React.FC<AvatarUsernameProps> = ({
  user,
  size = "sm",
}) => {
  const getSize = () => {
    switch (size) {
      case "sm":
        return 24;
      case "xs":
        return 20;
    }
  };
  return (
    <Link
      className="w-fit flex items-center gap-[5px] whitespace-nowrap cursor-pointer hover:opacity-70 transition-opacity duration-300"
      to={`/profile/${user.username}`}
    >
      <div className="sm:block hidden">
        <Avatar avatar={user.avatar} size={getSize()} />
      </div>
      <Text size={size} className="max-w-[150px] overflow-hidden text-ellipsis">
        {user.nickname ?? user.username}
      </Text>
    </Link>
  );
};

export default AvatarUsername;
