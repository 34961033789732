import { useNotification } from "react-easy-notification";
import { useMutation } from "@tanstack/react-query";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import { UserReplyDto } from "../../types/Post";
import { updatePostBy } from "./utils";

const useDeleteReply = (postId: number) => {
  const { pushNotification } = useNotification();
  const { mutate, isPending } = useMutation<unknown, unknown, UserReplyDto>({
    mutationFn: (reply) => httpClient.delete(`/reply/delete/${reply.id}`),
    onSuccess: () => {
      updatePostBy(postId, (post) => {
        post.replyCount -= 1;
      });
      queryClient
        .invalidateQueries({
          queryKey: [QueryKey.POST_REPLIES, postId],
        })
        .then(() => {
          pushNotification({
            type: "success",
            text: "Successfully deleted the reply.",
          });
        });
    },
    onError: () => {
      pushNotification({
        text: "Failed to delete the reply.",
        type: "danger",
      });
    },
  });
  return {
    deleteReply: mutate,
    deleting: isPending,
  };
};

export default useDeleteReply;
