import { useMutation } from "@tanstack/react-query";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import { useNotification } from "react-easy-notification";

const useOrderSettlement = () => {
  const { pushNotification } = useNotification();
  const { mutate: settleOrder, isPending: isSettling } = useMutation({
    mutationFn: (orderId: number) =>
      httpClient.post(`/order/${orderId}/settle`),
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QueryKey.ORDERS] })
        .then(() => {
          pushNotification({
            text: "Successfully settled the order.",
            type: "success",
          });
        });
    },
    onError: () => {
      pushNotification({
        text: "Failed to settle the order.",
        type: "danger",
      });
    },
  });

  const { mutate: unsettleOrder, isPending: isUnsettling } = useMutation({
    mutationFn: (orderId: number) =>
      httpClient.post(`/order/${orderId}/unsettle`),
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QueryKey.ORDERS] })
        .then(() => {
          pushNotification({
            text: "Successfully unsettled the order.",
            type: "success",
          });
        });
    },
    onError: () => {
      pushNotification({
        text: "Failed to unsettle the order.",
        type: "danger",
      });
    },
  });

  return {
    settleOrder,
    isSettling,
    unsettleOrder,
    isUnsettling,
  };
};

export default useOrderSettlement;
