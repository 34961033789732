import { PostDto } from "../../types/Post";
import React, { ReactNode, useEffect, useState } from "react";
import Modal from "../../component/Modal";
import { FiEdit, FiPlus } from "react-icons/fi";
import Button from "../../component/Button";
import Text from "../../component/Text";
import FileUpload from "../../component/FileUpload";
import Input from "../../component/Input";
import useCreatePost from "../../hook/post/useCreatePost";
import {
  compressFile,
  compressFiles,
  CompressLevel,
} from "../../utils/fileUtils";
import useAmendPost from "../../hook/post/useAmendPost";
import usePostImages from "../../hook/post/usePostImages";

interface PostErrors {
  title: string;
  content: string;
  general: string;
}

const initialErrors: PostErrors = {
  title: "",
  content: "",
  general: "",
};

interface CreatePostModalProps {
  action: "CREATE";
}

interface EditPostModalProps {
  action: "EDIT";
  post: PostDto;
}

const ImageWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className="aspect-1/1 bg-whitegray rounded-[6px] overflow-hidden">
      {children}
    </div>
  );
};

const CreateEditPostModal: React.FC<
  CreatePostModalProps | EditPostModalProps
> = (props) => {
  const IMAGE_SIZE_LIMIT = 3;
  const isCreate = props.action === "CREATE";
  const { images: imageBlobs, isFetching: fetchingImages } = usePostImages(
    isCreate ? null : props.post,
    IMAGE_SIZE_LIMIT
  );

  const initImages = imageBlobs.map((image) => new File([image], ""));
  useEffect(() => {
    setImages(initImages);
  }, [fetchingImages]);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(isCreate ? "" : props.post.title);
  const [content, setContent] = useState(isCreate ? "" : props.post.content);
  const [imageStrs, setImageStrs] = useState<(string | null)[]>(
    isCreate ? [] : props.post.images
  );
  const [images, setImages] = useState(isCreate ? [] : initImages);
  const [errors, setErrors] = useState({ ...initialErrors });

  const { createPost, isLoading: isCreating } = useCreatePost();
  const { amendPost, isLoading: isAmending } = useAmendPost();

  const setError = (key: keyof PostErrors, value: string) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const onClose = () => {
    setOpen(false);
    reset();
  };

  useEffect(() => {
    reset();
  }, [props]);

  const reset = () => {
    setTitle(isCreate ? "" : props.post.title);
    setContent(isCreate ? "" : props.post.content);
    setImageStrs(isCreate ? [] : props.post.images);
    setImages(isCreate ? [] : initImages);
    setErrors({ ...initialErrors });
  };

  const validatePost = () => {
    let valid = true;
    setErrors(() => {
      return { ...initialErrors };
    });
    if (!content) {
      setError("content", "Required");
      valid = false;
    }
    if (!title) {
      setError("title", "Required");
      valid = false;
    }
    return valid;
  };

  const onSubmit = () => {
    if (validatePost()) {
      if (isCreate) {
        createPost(
          [
            {
              title,
              content,
            },
            images,
          ],
          {
            onSuccess: onClose,
            onError: (err) =>
              setError(
                "general",
                err instanceof Error
                  ? err.message
                  : "Failed to create the post."
              ),
          }
        );
      } else {
        amendPost(
          [
            {
              id: props.post.id,
              title,
              content,
              images: imageStrs,
            },
            images,
          ],
          {
            onSuccess: onClose,
            onError: (err) =>
              setError(
                "general",
                err instanceof Error ? err.message : "Failed to edit the post."
              ),
          }
        );
      }
    }
  };

  return (
    <Modal
      triggerFullWidth={isCreate}
      trigger={
        isCreate ? (
          <Button
            icon={<FiPlus size="20px" />}
            fullWidth
            rounded={false}
            text="Create Post"
            onClick={() => {}}
          />
        ) : (
          <Text type="secondary" pointer>
            <FiEdit className="mobile:text-[16px] text-[14px]" />
          </Text>
        )
      }
      open={open}
      title={isCreate ? "Create Post" : "Edit Post"}
      onOpen={() => setOpen(true)}
      onClose={onClose}
    >
      <div className="flex flex-col gap-[10px]">
        <div className="w-full flex flex-col">
          <Input
            type="text"
            title="Title"
            onChange={setTitle}
            value={title}
            error={errors.title}
          />
          <Input
            type="textarea"
            title="Content"
            onChange={setContent}
            value={content}
            error={errors.content}
            inputClassName="h-[200px]"
          />
        </div>
        <div className="grid grid-cols-3 gap-[10px]">
          {images.map((image, index) => (
            <ImageWrapper key={index}>
              <FileUpload
                file={image}
                multiple={false}
                onChange={(file) => {
                  if (file) {
                    const newImages = [...images];
                    compressFile(
                      CompressLevel.HIGH,
                      file,
                      (result) => {
                        newImages[index] = result;
                        setImages(newImages);
                        setImageStrs((imageStrs) => {
                          const newImageStrs = [...imageStrs];
                          newImageStrs[index] = null;
                          return newImageStrs;
                        });
                      },
                      (err) => {
                        setError("general", err.message);
                      }
                    );
                  }
                }}
                onDelete={() => {
                  setImages((oldImages) => {
                    const newImages = [...oldImages];
                    newImages.splice(index, 1);
                    return newImages;
                  });
                  setImageStrs((oldImageStrs) => {
                    const newImageStrs = [...oldImageStrs];
                    newImageStrs.splice(index, 1);
                    return newImageStrs;
                  });
                }}
                type="image"
              />
            </ImageWrapper>
          ))}
          {images.length < IMAGE_SIZE_LIMIT && (
            <ImageWrapper>
              <FileUpload
                type="image"
                multiple={true}
                limit={IMAGE_SIZE_LIMIT - images.length}
                file={null}
                onChange={(files) => {
                  compressFiles(
                    CompressLevel.HIGH,
                    files,
                    (result) => {
                      setImages((prevImages) => {
                        return [...prevImages, result];
                      });
                      setImageStrs((imageStrs) => {
                        return [...imageStrs, null];
                      });
                    },
                    (err) => {
                      setError("general", err.message);
                    }
                  );
                }}
                placeholder={`Upload Image\r\n(${
                  IMAGE_SIZE_LIMIT - images.length
                } Remaining)`}
              />
            </ImageWrapper>
          )}
        </div>
        {errors.general && (
          <div className="w-full flex justify-end">
            <Text type="danger" size="xs">
              {errors.general}
            </Text>
          </div>
        )}
        <div className="flex justify-end gap-[10px]">
          <Button
            type="outline"
            color="red"
            text="Cancel"
            onClick={() => {
              setOpen(false);
              reset();
            }}
          />
          <Button
            loading={isCreating || isAmending}
            text={isCreate ? "Create" : "Edit"}
            onClick={onSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateEditPostModal;
