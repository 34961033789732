import Text from "../../component/Text";
import { getFormatedDateTime } from "../../utils/dateUtils";
import DeletePostModal from "./DeletePostModal";
import { GoTrash } from "react-icons/go";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { BiLike, BiSolidLike } from "react-icons/bi";
import { FaRegStar, FaStar } from "react-icons/fa";
import React from "react";
import { PostDto } from "../../types/Post";
import useSelf from "../../hook/user/useSelf";
import useLikePost from "../../hook/post/useLikePost";
import useAddFavoritePost from "../../hook/post/useAddFavoritePost";
import Action from "./Action";
import CreateEditPostModal from "./CreateEditPostModal";

const PostActionGroup = ({
  post,
  onReply,
}: {
  post: PostDto;
  onReply: () => void;
}) => {
  const { self } = useSelf();
  const { likePost, liking } = useLikePost();
  const { addFavorite, adding } = useAddFavoritePost();
  return (
    <div className="w-full flex justify-end gap-[10px]">
      <Text type="secondary" size="xs">
        {getFormatedDateTime(post.createdAt)}
      </Text>
      {self && self.id === post.user.id && (
        <>
          <CreateEditPostModal action="EDIT" post={post} />
          <DeletePostModal
            post={post}
            trigger={<Action authenticated icon={<GoTrash />} />}
          />
        </>
      )}
      <Action
        authenticated={!!self}
        icon={<IoChatboxEllipsesOutline />}
        text={post.replyCount.toString()}
        onClick={onReply}
      />
      <Action
        authenticated={!!self}
        loading={liking}
        icon={post.liked ? <BiSolidLike /> : <BiLike />}
        onClick={() => likePost(post)}
        text={post.likeCount.toString()}
      />
      <Action
        authenticated={!!self}
        loading={adding}
        icon={post.addedFavorite ? <FaStar /> : <FaRegStar />}
        onClick={() => addFavorite(post)}
        text={post.favoriteCount.toString()}
      />
    </div>
  );
};

export default PostActionGroup;
