import React, { useState } from "react";
import Text from "../component/Text";
import Input from "../component/Input";
import Button from "../component/Button";
import TextLink from "../component/TextLink";
import useSignUp from "../hook/auth/useSignUp";
import { useNavigate } from "react-router-dom";
import Logo from "../component/Logo";
import {
  validateEmailFormat,
  validatePasswordFormat,
} from "../utils/formatUtils";

interface Errors {
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
  general: string;
}

const initialErrors: Errors = {
  email: "",
  username: "",
  password: "",
  confirmPassword: "",
  general: "",
};

const SignUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [username, setUseName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errors, setErrors] = useState<Errors>(initialErrors);
  const [loading, setLoading] = useState(false);

  const { signup } = useSignUp();

  const setError = (key: keyof Errors, value: string) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const isValid = () => {
    let valid = !!(email && username && password && confirmPassword);
    setErrors(() => {
      return {
        email: email ? "" : "Required",
        username: username ? "" : "Required",
        password: password ? "" : "Required",
        confirmPassword: confirmPassword ? "" : "Required",
        general: "",
      };
    });
    if (!valid) return valid;
    try {
      validateEmailFormat(email);
    } catch (err) {
      if (err instanceof Error) {
        valid = false;
        setError("email", err.message);
      }
    }
    try {
      validatePasswordFormat(password);
    } catch (err) {
      if (err instanceof Error) {
        valid = false;
        setError("password", err.message);
      }
    }
    if (confirmPassword !== password) {
      valid = false;
      setError("confirmPassword", "Password does not match");
    }
    return valid;
  };

  const submit = async () => {
    if (isValid()) {
      setLoading(true);
      return signup({
        username,
        email,
        password,
      })
        .then(() => {
          setTimeout(() => {
            navigate("/");
          }, 500);
        })
        .catch((err: Error) => {
          setErrors((prevState) => {
            return {
              ...prevState,
              general: err.message,
            };
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="signup flex justify-center py-[30px]">
      <div className="form sm:w-[400px] w-[300px] h-fit rounded-[10px] bg-white py-[30px] px-[20px] flex flex-col items-center gap-[10px]">
        <Logo link={false} size="lg" />
        <div className="title">
          <Text type="secondary" size="lg">
            Sign Up
          </Text>
        </div>
        <div className="divider w-full h-[1px] bg-whitegray" />
        <div className="inputs w-full">
          <Input
            type="text"
            title="Email"
            value={email}
            onChange={setEmail}
            error={errors.email}
          />
          <Input
            type="text"
            title="Username"
            value={username}
            onChange={setUseName}
            error={errors.username}
          />
          <Input
            type="text"
            title="Password"
            masked
            value={password}
            onChange={setPassword}
            error={errors.password}
          />
          <Input
            type="text"
            title="Confirm Password"
            masked
            value={confirmPassword}
            onChange={setConfirmPassword}
            error={errors.confirmPassword}
          />
        </div>
        <div className="w-full flex justify-end">
          <TextLink to="/signin" size="xs" type="secondary">
            Already have an account?
          </TextLink>
        </div>
        {errors.general && (
          <div>
            <Text type="danger" size="xs">
              {errors.general}
            </Text>
          </div>
        )}
        <Button
          fullWidth
          color="primary"
          text="Sign Up"
          rounded
          loading={loading}
          onClick={submit}
        />
      </div>
    </div>
  );
};

export default SignUp;
