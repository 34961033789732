import LogoWithText from "../assets/LogoWithText_600.png";
import React from "react";
import Text from "../component/Text";
import MainBox from "../component/MainBox";

const Logo = () => {
  return (
    <div className="logo w-[220px]">
      <img src={LogoWithText} alt="Logo" />
    </div>
  );
};

const CopyRight = () => {
  return (
    <div>
      <Text size="sm">Copyright © 2024 Crazy Collectors</Text>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="w-full footer bg-white flex justify-center p-[20px]">
      <MainBox className="flex flex-col items-center gap-[5px]">
        <Logo />
        <CopyRight />
      </MainBox>
    </div>
  );
};

export default Footer;
