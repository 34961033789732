import { useMutation } from "@tanstack/react-query";
import { Collectible } from "../../types/Collectible";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import { useNotification } from "react-easy-notification";

const useDeleteCollectible = () => {
  const { pushNotification } = useNotification();
  const { mutate, isPending, error } = useMutation<
    Readonly<Collectible>,
    unknown,
    number
  >({
    mutationFn: (collectibleId) =>
      httpClient.delete(`/collectible/${collectibleId}`),
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QueryKey.COLLECTIBLES] })
        .then(() => {
          pushNotification({
            text: "Successfully deleted the collectible.",
            type: "success",
          });
        });
    },
    onError: () => {
      pushNotification({
        text: "Failed to delete the collectible.",
        type: "danger",
      });
    },
  });

  return {
    deleteCollectible: mutate,
    isLoading: isPending,
    error,
  };
};

export default useDeleteCollectible;
