import React, { useState } from "react";
import Text from "../../component/Text";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { usePost, usePostReplies } from "../../hook/post/postDetailHooks";
import AvatarUsername from "../../component/AvatarUsername";
import PostActionGroup from "./PostActionGroup";
import Button from "../../component/Button";
import LoadingWithText from "../../component/LoadingWithText";
import useSelf from "../../hook/user/useSelf";
import Input from "../../component/Input";
import useReplyPost from "../../hook/post/useReplyPost";
import PostReply from "./PostReply";
import { UserReplyDto } from "../../types/Post";
import useReplyReply from "../../hook/post/useReplyReply";
import { useRecoilState } from "recoil";
import { postDetailFromState } from "../../state/postDetailFromState";
import PostImage from "./PostImage";

const PostDetails = () => {
  const { self } = useSelf();
  const { postId: postIdStr } = useParams();
  const postId = postIdStr ? Number.parseInt(postIdStr) : 0;
  const { post, isLoading: loadingPost } = usePost(postId);
  const { replyPost, replying: replyingPost } = useReplyPost();
  const { replyReply, replying: replyingReply } = useReplyReply(postId);
  const navigate = useNavigate();
  const [from] = useRecoilState(postDetailFromState);
  const {
    replies,
    isLoading: loadingReplies,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = usePostReplies(postId);

  const [inputtingReply, setInputtingReply] = useState(false);
  const [replyToReply, setReplyToReply] = useState<UserReplyDto | null>(null);
  const [reply, setReply] = useState("");

  const isReplyPost = replyToReply === null;

  return (
    <div className="w-full h-full relative overflow-hidden flex flex-col gap-[10px]">
      {from !== null && (
        <div className="header">
          <div
            className="flex items-center w-fit gap-[5px] text-secondary cursor-pointer hover:opacity-70 transition-opacity duration-300"
            onClick={() =>
              navigate(
                `/community/${from === "LATEST" ? "latest" : "following"}`
              )
            }
          >
            <Text type="secondary">
              <IoIosArrowBack />
            </Text>
            <Text type="secondary" size="sm">
              Back
            </Text>
          </div>
        </div>
      )}
      <div className="post w-full grow bg-white rounded-[6px] overflow-hidden">
        <div className="overflow-auto h-full pb-[60px]">
          {loadingPost || !post ? (
            <LoadingWithText text="Loading post..." />
          ) : (
            <>
              <div className="title flex items-center justify-between p-[10px] border-b-[1px] border-whitegray">
                <Text size="sm" weight="bold">
                  {post.title}
                </Text>
                <AvatarUsername user={post.user} />
              </div>
              <div className="content flex flex-col gap-[10px] p-[10px]">
                <Text type="secondary" size="sm" inline={false} wrap="prewrap">
                  {post.content}
                </Text>
                {post.images.length > 0 && (
                  <div className="images flex sm:flex-row flex-col gap-[5px]">
                    {post.images.map((image) => (
                      <PostImage key={image} image={image} postId={post.id} />
                    ))}
                  </div>
                )}
                <PostActionGroup
                  post={post}
                  onReply={() => {
                    setReplyToReply(null);
                    setInputtingReply(true);
                  }}
                />
              </div>
              {loadingReplies && <LoadingWithText text="Loading replies..." />}
              {replies.map((reply) => (
                <PostReply
                  key={reply.id}
                  reply={reply}
                  onReply={(reply) => {
                    setReplyToReply(reply);
                    setInputtingReply(true);
                  }}
                />
              ))}
              {hasNextPage && (
                <div className="w-full flex justify-center py-[15px]">
                  <Button
                    loading={isFetchingNextPage}
                    type="outline"
                    color="secondary"
                    text="Load More Replies"
                    onClick={fetchNextPage}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {self && (
        <div className="reply w-full absolute bottom-0 p-[5px] bg-white shadow-popup">
          <div
            className={`w-full flex items-center ${
              !inputtingReply ? "h-[0px]" : "h-[30px]"
            } transition-height duration-200 overflow-hidden`}
          >
            <Text
              className="w-full overflow-hidden whitespace-nowrap text-ellipsis"
              size="sm"
              weight="bold"
            >
              {isReplyPost
                ? `To Post: ${post?.title}`
                : `To Reply: ${replyToReply?.content}`}
            </Text>
          </div>
          <Input
            type="textarea"
            value={reply}
            inputClassName={inputtingReply ? "h-[200px]" : "h-[20px]"}
            onChange={setReply}
            onFocus={() => setInputtingReply(true)}
            placeholder="Post your reply here..."
          />
          <div
            className={`w-full flex justify-end gap-[10px] ${
              !inputtingReply ? "h-[0px]" : "h-[32px] py-[3px]"
            } transition-height duration-200 overflow-hidden`}
          >
            <Button
              text="Cancel"
              color="red"
              onClick={() => {
                setReplyToReply(null);
                setInputtingReply(false);
                setReply("");
              }}
              size="xs"
            />
            <Button
              text="Post Reply"
              onClick={() => {
                if (isReplyPost) {
                  replyPost(
                    {
                      id: postId,
                      content: reply,
                    },
                    {
                      onSuccess: () => {
                        setReply("");
                        setInputtingReply(false);
                      },
                    }
                  );
                } else {
                  replyReply(
                    {
                      id: replyToReply?.id,
                      content: reply,
                    },
                    {
                      onSuccess: () => {
                        setReply("");
                        setInputtingReply(false);
                      },
                    }
                  );
                }
              }}
              size="xs"
              loading={replyingPost || replyingReply}
              disabled={!reply}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PostDetails;
