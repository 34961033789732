import { useMutation } from "@tanstack/react-query";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import { useNotification } from "react-easy-notification";

interface ReplyReplyRequest {
  id: number;
  content: string;
}

const useReplyReply = (postId: number) => {
  const { pushNotification } = useNotification();
  const { mutate, isPending } = useMutation<
    unknown,
    unknown,
    ReplyReplyRequest
  >({
    mutationFn: (request) =>
      httpClient.post<ReplyReplyRequest, unknown>("/reply/reply", request),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QueryKey.POST_REPLIES, postId],
        })
        .then(() => {
          pushNotification({
            type: "success",
            text: "Successfully post the reply.",
          });
        });
    },
    onError: () => {
      pushNotification({
        type: "danger",
        text: "Failed to post the reply.",
      });
    },
  });

  return {
    replyReply: mutate,
    replying: isPending,
  };
};

export default useReplyReply;
