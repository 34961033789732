import React, { useState } from "react";
import Text from "../component/Text";
import Input from "../component/Input";
import Button from "../component/Button";
import TextLink from "../component/TextLink";
import { useNavigate } from "react-router-dom";
import useSignIn from "../hook/auth/useSignIn";
import Logo from "../component/Logo";

interface Errors {
  name: string;
  password: string;
  general: string;
}

const initialErrors: Errors = {
  name: "",
  password: "",
  general: "",
};

const SignIn = () => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<Errors>(initialErrors);
  const [loading, setLoading] = useState(false);

  const { signin } = useSignIn();

  const isValid = () => {
    let valid = !!(name && password);
    setErrors(() => {
      return {
        name: name ? "" : "Required",
        password: password ? "" : "Required",
        general: "",
      };
    });
    return valid;
  };

  const submit = async () => {
    if (isValid()) {
      setLoading(true);
      return signin({
        name: name,
        password,
      })
        .then(() => {
          setTimeout(() => {
            navigate("/");
          }, 500);
        })
        .catch((err: Error) => {
          setErrors((prevState) => {
            return {
              ...prevState,
              general: err.message,
            };
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="signup flex justify-center py-[30px]">
      <div className="form sm:w-[400px] w-[300px] h-fit rounded-[10px] bg-white py-[30px] px-[20px] flex flex-col items-center gap-[10px]">
        <Logo link={false} size="lg" />
        <div className="title">
          <Text type="secondary" size="lg">
            Sign In
          </Text>
        </div>
        <div className="divider w-full h-[1px] bg-whitegray" />
        <div className="inputs w-full">
          <Input
            type="text"
            title="Email / Username"
            value={name}
            onChange={setName}
            error={errors.name}
          />
          <Input
            type="text"
            title="Password"
            masked
            value={password}
            onChange={setPassword}
            error={errors.password}
          />
        </div>
        <div className="w-full flex flex-col items-end">
          <TextLink to="/signup" size="xs" type="secondary">
            Don't have an account yet?
          </TextLink>
          <TextLink to="/forget" size="xs" type="secondary">
            Forgot your password?
          </TextLink>
        </div>
        {errors.general && (
          <div>
            <Text type="danger" size="xs">
              {errors.general}
            </Text>
          </div>
        )}
        <Button
          fullWidth
          color="primary"
          text="Sign In"
          rounded
          loading={loading}
          onClick={submit}
        />
      </div>
    </div>
  );
};

export default SignIn;
