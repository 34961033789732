import useSelf from "../hook/user/useSelf";
import MainBox from "../component/MainBox";
import Welcome from "../assets/HomeWelcome.jpg";
import Text from "../component/Text";
import Button from "../component/Button";
import { useNavigate } from "react-router-dom";

const Section = ({
  title,
  description,
  background,
  align = "start",
  buttonText,
  onButtonClick,
}: {
  title: string;
  description: string;
  background?: string;
  align?: "start" | "end";
  buttonText: string;
  onButtonClick: () => void;
}) => {
  const color = background ? "white" : "primary";
  return (
    <div
      className="welcome relative w-full flex justify-center h-[700px] bg-center bg-no-repeat bg-cover"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      {background && (
        <div className="absolute w-full h-full bg-black opacity-50" />
      )}
      <MainBox className={`h-full flex justify-${align} items-center z-[5]`}>
        <div className="sm:w-[690px] mobile:w-[500px] w-[360px] flex flex-col">
          <Text type={color} weight="light" inline={false} size="banner">
            {title}
          </Text>
          <Text type={color} weight="light" inline={false} size="sub-banner">
            {description}
          </Text>
          <Button
            className="mt-[10px]"
            type="outline"
            color={color}
            rounded
            text={buttonText}
            onClick={onButtonClick}
          />
        </div>
      </MainBox>
    </div>
  );
};

const Home = () => {
  const { self } = useSelf();
  const navigate = useNavigate();
  return (
    <div className="home w-full flex flex-col gap-[10px] bg-white">
      <Section
        title={self ? `Welcome, ${self.username}` : "Welcome"}
        description="to Crazy Collectors, a utopia dedicated to collectors."
        background={Welcome}
        buttonText="Start Journey"
        onButtonClick={() => {}}
      />
      <Section
        title="Community for Collectors"
        description="A great platform for collectors to share their collection and opinions."
        buttonText="Share My Story"
        align="end"
        onButtonClick={() => navigate("/forum")}
      />
      <Section
        title="Order Management"
        description="A place to centralize your great collectible orders."
        background={Welcome}
        buttonText="Manage Orders"
        onButtonClick={() => navigate("/orders")}
      />
      <Section
        title="Collection Gallery"
        description="Add collectibles to build your awesome collection gallery."
        background={Welcome}
        buttonText="View Gallery"
        align="end"
        onButtonClick={() => navigate("/collection")}
      />
      <Section
        title="Collectible News"
        description="Keep up with the latest news about collectibles all over the world."
        buttonText="Read News"
        onButtonClick={() => navigate("/news")}
      />
    </div>
  );
};

export default Home;
