import { useMutation } from "@tanstack/react-query";
import httpClient from "../../http/httpClient";
import { updatePostBy } from "./utils";
import { PostDto } from "../../types/Post";

const useLikePost = () => {
  const { mutate, isPending } = useMutation<unknown, unknown, PostDto>({
    mutationFn: (post) =>
      post.liked
        ? httpClient.delete(`/post/like/${post.id}`)
        : httpClient.post(`/post/like/${post.id}`),
    onSuccess: (_, post) => {
      updatePostBy(post.id, (post) => {
        if (post.liked) {
          post.likeCount -= 1;
          post.liked = false;
        } else {
          post.likeCount += 1;
          post.liked = true;
        }
      });
    },
  });

  return {
    likePost: mutate,
    liking: isPending,
  };
};

export default useLikePost;
