import React, { useMemo } from "react";
import useAvatar from "../hook/profile/useAvatar";
import Loading from "./Loading";
import { IoPersonOutline } from "react-icons/io5";

interface AvatarProps {
  avatar: string | null;
  size: number;
  className?: string;
}

const Avatar: React.FC<AvatarProps> = ({ avatar, size, className }) => {
  const { avatar: avatarBlob, isLoading } = useAvatar(avatar);
  const avatarUrl = useMemo(() => {
    return avatarBlob ? URL.createObjectURL(avatarBlob) : null;
  }, [avatarBlob]);

  return (
    <div
      className={`bg-whitegray overflow-hidden ${className}`}
      style={{
        width: size,
        height: size,
        borderRadius: size / 2,
      }}
    >
      {avatarUrl ? (
        <img
          alt="Avatar PostImage"
          className="w-full h-full object-cover"
          src={avatarUrl}
        />
      ) : (
        <div className="w-full h-full flex justify-center items-center text-secondary opacity-60">
          {isLoading ? (
            <Loading color="secondary" />
          ) : (
            <IoPersonOutline size={size / 2} />
          )}
        </div>
      )}
    </div>
  );
};

export default Avatar;
