import { useMutation } from "@tanstack/react-query";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import { useNotification } from "react-easy-notification";
import useSelf from "./useSelf";

const useUserFollow = (isFollow: boolean) => {
  const { pushNotification } = useNotification();
  const { self } = useSelf();
  const { mutate, isPending } = useMutation<void, unknown, string>({
    mutationFn: (username) =>
      httpClient.post(`/user/${isFollow ? "follow" : "unfollow"}/${username}`),
    onSuccess: async (_, username) => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QueryKey.UNFOLLOWED_USERS],
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKey.NOTIFICATIONS],
        }),
        queryClient.invalidateQueries({ queryKey: [QueryKey.FOLLOWINGS] }),
        queryClient.invalidateQueries({ queryKey: [QueryKey.FOLLOWERS] }),
        queryClient.invalidateQueries({
          queryKey: [QueryKey.POSTS_ALL_FOLLOWING],
        }),
        queryClient.invalidateQueries({ queryKey: [QueryKey.USER, username] }),
        queryClient.invalidateQueries({
          queryKey: [QueryKey.USER, self?.username],
        }),
      ]).then(() => {
        pushNotification({
          text: `Successfully ${
            isFollow ? "followed" : "unfollowed"
          } the collector.`,
          type: "success",
        });
      });
    },
    onError: () => {
      pushNotification({
        text: `Failed to ${isFollow ? "follow" : "unfollow"} the collector.`,
        type: "danger",
      });
    },
  });

  return {
    followUser: mutate,
    isLoading: isPending,
  };
};

export default useUserFollow;
