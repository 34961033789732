import Compressor from "compressorjs";
import httpClient from "../http/httpClient";

export enum CompressLevel {
  HIGH,
  MEDIUM,
  LOW,
}

const getQuality = (level: CompressLevel, size: number) => {
  if (level === CompressLevel.HIGH) return 0.2;
  if (level === CompressLevel.MEDIUM) {
    return size > 10485760 // 10MB
      ? 0.5
      : size > 3145728 // 1MB
      ? 0.6
      : 0.8;
  }
  if (level === CompressLevel.LOW) {
    return 0.8;
  }
};

export const compressFile = <T>(
  level: CompressLevel,
  file: File,
  onSuccess: (result: File) => T,
  onError?: (err: Error) => void
) => {
  return new Compressor(file, {
    quality: getQuality(level, file.size),
    success: (result) => {
      if (result.size > 1048576) {
        // 1MB
        const error = new Error(
          "Failed to process image. Please choose another image."
        );
        if (onError) {
          onError(error);
        } else {
          throw error;
        }
      } else {
        return onSuccess(
          result instanceof Blob ? new File([result], file.name) : result
        );
      }
    },
    error(err) {
      onError?.(err);
    },
  });
};

export const compressFiles = <T>(
  level: CompressLevel,
  files: File[],
  onSuccess: (result: File) => T,
  onError?: (err: Error) => void
) => {
  if (!files.length) return;
  const file = files[0];
  return compressFile(
    level,
    file,
    (result) => {
      onSuccess(result);
      compressFiles(level, files.slice(1, files.length), onSuccess, onError);
    },
    onError
  );
};
