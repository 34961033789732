import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../query/queryKey";
import httpClient from "../http/httpClient";

interface InitialState {
  newNotificationCount: number;
}

const useAppInitialState = (authed: boolean) => {
  const { data, isLoading } = useQuery<InitialState>({
    queryKey: [QueryKey.INITIAL_STATE],
    queryFn: () => httpClient.get("/app/init"),
    gcTime: Infinity,
    staleTime: Infinity,
  });
  return {
    initialState: data ?? null,
    loadingInitialState: isLoading,
  };
};

export default useAppInitialState;
