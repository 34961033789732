import { Currency } from "../../types/shared";
import { useMutation } from "@tanstack/react-query";
import { Collectible } from "../../types/Collectible";
import httpClient from "../../http/httpClient";
import queryClient from "../../query/queryClient";
import { QueryKey } from "../../query/queryKey";
import useSelf from "../user/useSelf";
import { useNotification } from "react-easy-notification";

interface AmendCollectibleRequest {
  id: number;
  brand: string;
  currency: Currency;
  name: string;
  price: number;
  public: boolean;
  image: string | null; // Kept as reference to the old image so it can be invalidated from cache
  addedAt: Date;
  comment: string | null;
}

const useAmendCollectible = () => {
  const { self } = useSelf();
  const { pushNotification } = useNotification();
  const { mutate, isPending, error } = useMutation<
    Readonly<Collectible>,
    unknown,
    [AmendCollectibleRequest, File | null]
  >({
    mutationFn: (collectible) =>
      httpClient.post<AmendCollectibleRequest, Readonly<Collectible>>(
        "/collectible/amend",
        collectible[0],
        collectible[1] ?? new File([""], "")
      ),
    onSuccess: (collectible, request) => {
      Promise.all([
        queryClient.invalidateQueries({ queryKey: [QueryKey.COLLECTIBLES] }),
        queryClient.invalidateQueries({
          queryKey: [[QueryKey.USER_COLLECTIBLES, self?.id]],
        }),
      ]).then(() => {
        queryClient.removeQueries({
          queryKey: [QueryKey.COLLECTIBLE_IMAGE, request[0].image],
        });
        pushNotification({
          text: "Successfully edited the collectible.",
          type: "success",
        });
      });
    },
    onError: () => {
      pushNotification({
        text: "Failed to edit the collectible.",
        type: "danger",
      });
    },
  });
  return {
    amendCollectible: mutate,
    isLoading: isPending,
    error,
  };
};

export default useAmendCollectible;
