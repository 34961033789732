import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import MainBox from "../../component/MainBox";
import React, { ReactNode, useMemo } from "react";
import { AiOutlineFire } from "react-icons/ai";
import { RiUserFollowLine } from "react-icons/ri";
import Text from "../../component/Text";
import { PiFireSimpleFill } from "react-icons/pi";
import { SelfDto } from "../../types/User";
import useAvatar from "../../hook/profile/useAvatar";
import Loading from "../../component/Loading";
import { IoPersonOutline } from "react-icons/io5";
import Button from "../../component/Button";
import useUnfollowedUsers from "../../hook/user/useUnfollowedUsers";
import useUserFollow from "../../hook/user/useUserFollow";
import useSelf from "../../hook/user/useSelf";
import CreateEditPostModal from "./CreateEditPostModal";

const HeaderNavLink = ({
  to,
  text,
  icon,
}: {
  to: string;
  text: string;
  icon: ReactNode;
}) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `relative flex items-center gap-[5px] h-[30px] px-[10px] rounded-[15px] text-sm transition duration-300 ${
          isActive ? "text-white bg-primary" : "text-primary"
        }`
      }
    >
      <span>{icon}</span>
      <span>{text}</span>
    </NavLink>
  );
};

const SidebarSection = ({
  title,
  children,
}: {
  title?: string;
  children: ReactNode;
}) => {
  return (
    <div className="flex flex-col gap-[5px]">
      {title && (
        <div className="title">
          <Text size="sm">{title}</Text>
        </div>
      )}
      {children}
    </div>
  );
};

const HotIcon = ({ num, hot }: { num: number; hot: boolean }) => {
  return (
    <div className="relative scale-[0.9]">
      <PiFireSimpleFill
        className={hot ? "text-orange" : "text-primary"}
        size="25px"
      />
      <Text
        className="absolute left-[9px] top-[6px]"
        weight="bold"
        type="white"
        size="xs"
      >
        {num}
      </Text>
    </div>
  );
};

const PopularTopic = ({
  text,
  num,
  isLast = false,
}: {
  text: string;
  num: number;
  isLast?: boolean;
}) => {
  return (
    <>
      <div className="flex p-[5px] gap-[2px] items-center cursor-pointer hover:opacity-70 transition-opacity duration-300">
        <HotIcon num={num} hot={num < 4} />
        <Text
          className="whitespace-nowrap overflow-hidden overflow-ellipsis"
          size="xs"
        >
          {text}
        </Text>
      </div>
      {!isLast && <div className="w-[90%] h-[1px] bg-whitegray mx-auto" />}
    </>
  );
};

const UserToFollow = ({
  user,
  vertical = false,
}: {
  user: SelfDto;
  vertical?: boolean;
}) => {
  const { avatar, isLoading: loadingAvatar } = useAvatar(user.avatar);
  const avatarUrl = useMemo(() => {
    return avatar ? URL.createObjectURL(avatar) : null;
  }, [avatar]);
  const { followUser, isLoading: following } = useUserFollow(true);
  return (
    <div
      className={`flex items-center gap-[5px] ${
        vertical
          ? "p-[10px] flex-col bg-white min-w-[150px] w-[150px] rounded-[6px]"
          : "p-[5px] w-full"
      }`}
    >
      <Link
        className={`flex gap-[5px] items-center grow cursor-pointer hover:opacity-70 transition-opacity duration-300 ${
          vertical ? "flex-col" : ""
        }`}
        to={`/profile/${user.username}`}
      >
        <div
          className={`bg-whitegray overflow-hidden ${
            vertical
              ? "w-[80px] h-[80px] rounded-[40px]"
              : "w-[26px] h-[26px] rounded-[13px]"
          }`}
        >
          {avatarUrl ? (
            <img
              alt="Avatar PostImage"
              className="w-full h-full object-cover"
              src={avatarUrl}
            />
          ) : (
            <div className="w-full h-full flex justify-center items-center text-secondary opacity-60">
              {loadingAvatar ? (
                <Loading color="secondary" size="12px" />
              ) : (
                <IoPersonOutline size="14px" />
              )}
            </div>
          )}
        </div>
        <Text className="grow" size="xs">
          {user.nickname ?? user.username}
        </Text>
      </Link>
      <Button
        fullWidth={vertical}
        size="xs"
        color="blue"
        text="Follow"
        onClick={() => followUser(user.username)}
        loading={following}
      />
    </div>
  );
};

const SuggestedUsersSection = ({ vertical }: { vertical: boolean }) => {
  const { users: unfollows, isLoading: loadingUnfollows } =
    useUnfollowedUsers();

  return (
    <SidebarSection title="Suggested for you">
      <div
        className={`flex ${
          vertical ? "" : "flex-col"
        } w-full max-w-full gap-[10px] overflow-auto`}
      >
        {loadingUnfollows ? (
          <Loading />
        ) : (
          unfollows.map((unfollow) => (
            <UserToFollow
              key={unfollow.id}
              user={unfollow}
              vertical={vertical}
            />
          ))
        )}
      </div>
    </SidebarSection>
  );
};

const HeaderNavs = () => {
  const { self } = useSelf();
  return (
    <div className="header absolute top-0 w-full flex justify-center items-center gap-[10px] min-h-[40px] h-[40px] bg-white rounded-[20px] shadow-light">
      <HeaderNavLink to="latest" text="Latest" icon={<AiOutlineFire />} />
      {!!self && (
        <HeaderNavLink
          to="following"
          text="Following"
          icon={<RiUserFollowLine />}
        />
      )}
    </div>
  );
};

const Community = () => {
  const { self } = useSelf();
  const location = useLocation();
  const canCreatePost = location.pathname.includes("/community/latest");
  const showNavs = !location.pathname.includes("/community/post/");
  const showSideBarOnMobile = !location.pathname.includes("/community/post/");

  return (
    <MainBox className="py-[10px] flex gap-[10px] grow h-full">
      {/*Need min-w-0 to prevent whitespace-nowrap from expanding the children div*/}
      <div
        className={`main relative max-w-full grow flex flex-col gap-[10px] min-w-0 ${
          showNavs ? "pt-[50px]" : ""
        }`}
      >
        {showNavs && (
          <div className="header absolute top-0 w-full flex justify-center items-center gap-[10px] min-h-[40px] h-[40px] bg-white rounded-[20px] shadow-light">
            <HeaderNavs />
          </div>
        )}
        <div
          className={`relative flex flex-col gap-[10px] grow sm:overflow-hidden ${
            showSideBarOnMobile ? "overflow-auto" : "overflow-hidden"
          }`}
        >
          {showSideBarOnMobile && (
            <div className="sm:hidden block">
              <SidebarSection title="Latest Collectibles">
                <div className="w-full aspect-4/3 bg-whitegray rounded-[6px]" />
              </SidebarSection>
              <SidebarSection title="Hot Topics">
                <div className="w-full bg-white rounded-[6px]">
                  <PopularTopic
                    num={1}
                    text="INART announced their next product for order!"
                  />
                  <PopularTopic
                    num={2}
                    text="INART announced their next product for order!"
                  />
                  <PopularTopic
                    num={3}
                    text="INART announced their next product for order!"
                  />
                  <PopularTopic
                    num={4}
                    text="INART announced their next product for order!"
                    isLast
                  />
                  <div className="w-full flex justify-center">
                    <Text size="xs" type="secondary" className="p-[10px]">
                      See more topics
                    </Text>
                  </div>
                </div>
              </SidebarSection>
              {self && <SuggestedUsersSection vertical />}
            </div>
          )}
          <div
            className={`sm:overflow-auto ${
              showSideBarOnMobile ? "overflow-visible" : "overflow-auto"
            } grow`}
          >
            <Outlet />
          </div>
        </div>
        {canCreatePost && (
          <div className="sm:hidden block absolute bottom-0 w-full">
            <CreateEditPostModal action="CREATE" />
          </div>
        )}
      </div>
      <div className="sidebar sm:flex hidden min-w-[240px] w-[240px] flex-col gap-[10px] overflow-y-auto">
        <SidebarSection title="Latest Collectibles">
          <div className="w-full h-[180px] bg-whitegray rounded-[6px]" />
        </SidebarSection>
        <SidebarSection title="Hot Topics">
          <div className="w-full bg-white rounded-[6px]">
            <PopularTopic
              num={1}
              text="INART announced their next product for order!"
            />
            <PopularTopic
              num={2}
              text="INART announced their next product for order!"
            />
            <PopularTopic
              num={3}
              text="INART announced their next product for order!"
            />
            <PopularTopic
              num={4}
              text="INART announced their next product for order!"
              isLast
            />
            <div className="w-full flex justify-center">
              <Text size="xs" type="secondary" className="p-[10px]">
                See more topics
              </Text>
            </div>
          </div>
        </SidebarSection>
        <SidebarSection>
          <CreateEditPostModal action="CREATE" />
        </SidebarSection>
        {self && <SuggestedUsersSection vertical={false} />}
      </div>
    </MainBox>
  );
};

export default Community;
