import { useQuery } from "@tanstack/react-query";
import httpClient from "../../http/httpClient";
import { QueryKey } from "../../query/queryKey";

const useOrderImage = (image: string | null) => {
  const { data, isLoading, error } = useQuery<Blob | null>({
    queryKey: [QueryKey.ORDER_IMAGE, image],
    queryFn: () => {
      return image ? httpClient.getFile<Blob>(`/blob/orders/${image}`) : null;
    },
    gcTime: 1200000,
    staleTime: 600000,
    retry: 0,
  });

  return {
    image: data ?? null,
    isLoading,
    error,
  };
};

export default useOrderImage;
